import { Box, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { useSelector } from "react-redux";
import { config } from "../../config";
import getLastCutoff from "../../func/getLastCutoff";
import { selectLookAhead, selectShowProgress, selectXIndex } from "../../slice";



export default function VerticalTodayIndicator() {

    const xIndex = useSelector(selectXIndex)
    const lookAhead = useSelector(selectLookAhead)
    const showProgress = useSelector(selectShowProgress)

    if (!('__today' in xIndex)) {
        return null
    }

    const x = xIndex['__today'].start
    const progressX = xIndex['__lastCutoff'].start
    const horizon = lookAhead === 4 ? xIndex['__today4w'].end
        : lookAhead === 6 ? xIndex['__today6w'].end
            : 0
    const top = 60 // (lane || 0) * 16
    const color = 'yellow'



    return (
        <React.Fragment>
            {lookAhead !== 0 ? (
                <Box
                    id="look-ahead"
                    position="absolute"
                    left={`${x}px`}
                    width={`${horizon - x}px`}
                    height={"100%"}
                    bg={`${color}.100`}
                    zIndex={-3}
                    opacity={.35}

                />
            ) : null}

            {showProgress ? (
                <React.Fragment>
                                <Box
                id="long-line"
                position="absolute"
                left={`${progressX}px`}
                width={`${config.milestoneLineWidth}px`}
                height={"100%"}
                bg={`red.500`}
                zIndex={-1}
                transform={`translateX(-50%)`}
                opacity={.25}

            />

            <Text
                id="label"

                transformOrigin={"left bottom"}
                position="absolute"
                left={`${progressX}px`}
                top={`${top}px`}
                fontSize={`${9}px`}
                color={`red.600`}
                transform={`rotate(90deg)`}
            // bg="red"
            >Cutoff {getLastCutoff(DateTime.now()).toLocaleString()}</Text>
                    </React.Fragment>
            ) : null}


            <Box
                id="long-line"
                position="absolute"
                left={`${x}px`}
                width={`${config.milestoneLineWidth}px`}
                height={"100%"}
                bg={`${color}.500`}
                zIndex={-1}
                transform={`translateX(-50%)`}
                opacity={.25}

            />

            <Text
                id="label"

                transformOrigin={"left bottom"}
                position="absolute"
                left={`${x}px`}
                top={`${top}px`}
                fontSize={`${9}px`}
                color={`${color}.600`}
                transform={`rotate(90deg)`}
            // bg="red"
            >I dag - {DateTime.now().toLocaleString()}</Text>

        </React.Fragment>
    )
}