import { Box, Button, Stack, Textarea } from "@chakra-ui/react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import processCSVIndep from "../../func/processCSVInDep"
import { selectElements, selectMasterIndex, setDependencies } from "../../slice"
import DepsRow from "./DepsRow"
import { footerBarHeight } from "./FooterBar"



export const inDepWidth = 1200


export default function DepsInsert() {

    let [value, setValue] = useState('')
    const dispatch = useDispatch()
    const elements = useSelector(selectElements)
    const masterIndex = useSelector(selectMasterIndex)

    const istIds = structuredClone(masterIndex.clss['ist']).sort((a, b) => elements[a].lane! < elements[b].lane! ? -1 : 1)


    let handleInputChange = (e: any) => {
        let inputValue = e.target.value
        setValue(inputValue)
    }

    const effectuateInsert = () => {
        if (value.length !== 0) {
            const output = processCSVIndep(value)
            dispatch(setDependencies(output))
            setValue('')
        }
    }

    return (
        <Box
            bg="whiteAlpha.800"
            position={"fixed"}
            right="0"
            bottom={`${footerBarHeight}px`}
            width={`${inDepWidth}px`}
            // height={"45vh"}
            shadow="xl"
            // overflow={"scroll"}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="3"
            p="2"
            m="2"
        >


            <Stack>
                <Stack
                    border="1px solid"
                    borderColor="gray.400"
                    p="3"
                    // height={"40vh"}
                >

                    <Textarea
                        value={value}
                        onChange={handleInputChange}
                        placeholder='From,To  ...'
                        size='sm'
                    />
                    <Button
                    onClick={effectuateInsert}
                    >Insert Dep</Button>

                </Stack>

                <Stack
                    border="1px solid"
                    borderColor="gray.400"
                    overflow="scroll"
                    height={"40vh"}
                >
                    <table
                        style={{
                            fontSize: "11px",
                        }}
                    >
                        <tbody>
                            {istIds.map((id: string) => (
                                <DepsRow key={id} id={id}  />
                            ))}
                        </tbody>
                    </table>
                </Stack>

            </Stack>



        </Box>
    )


}