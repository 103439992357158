import { DateTime } from "luxon";

export default function getLastCutoff(inputDate: DateTime): DateTime {

    let endOfMonth = inputDate.endOf('month')

    while (true) {

        // Calculate how many days back to get to the last Sunday
        const daysBack = endOfMonth.weekday % 7;
        const lastSunday = endOfMonth.minus({ days: daysBack });

        // If the last Sunday is in the past relative to the current date, return it
        if (lastSunday < inputDate) {
            return lastSunday
        }

        // Otherwise, move back another month and continue
        endOfMonth = endOfMonth.minus({ months: 1 }).endOf('month')

    }

}