import Graph from "graphology"
import { hasCycle } from "graphology-dag"
import { DateTime } from "luxon"
import { CombinedElementDict, Dependency, MasterIndex, RawLineDict, SpecLineDict, defaultMasterIndex, elementClass, lvl1, lvl2 } from "../types.d"

/**
 * 
      '331505': {
        seq: '19091',
        id: '331505',
        desc: 'Plan for etablering av prosjektdrift utarbeides',
        start: '2023-12-14T23:00:00.000Z',
        end: '2024-02-22T23:00:00.000Z',
        Varighet: 45,
        Entr: 'SPHF',
        Planlagt: 1,
        Faktisk: 1,
        Timer: 0.1,
        'BL Start': '2023-12-14T23:00:00.000Z',
        'BL Slutt': '2024-02-22T23:00:00.000Z',
        'BL Planlagt': 1,
        'Entr kommentar': 'Overordnet dokument utgitt. HSØ-8250-F-PL-0002',
        Statusindikator: 'grå',
        WBS: '4.1.1',
        'Nivå 1': '33 - Sykehuspartner',
        'Nivå 2': '15 - Hovedmilepæler generelt',
        'Fløy': '000 - Generelt - bygguavhengig',
        'Samlet Plan avhengigheter': 'x',
        'Samlet Plan Forum': '1'
      },

 */

export default function processUpdateElementsAndIndexes(rawData: RawLineDict, rawOrder: string[], specDict: SpecLineDict, dependencies: Dependency[], startRaw: [number, number, number]): {
    elements: CombinedElementDict,
    masterIndex: MasterIndex,
} {

    let elements: CombinedElementDict = {}
    let masterIndex: MasterIndex = structuredClone(defaultMasterIndex)


    // Initialize static indexes
    elementClass.forEach(elClass => masterIndex.clss[elClass] = [])
    lvl1.forEach(lvl1Id => {
        masterIndex.children[lvl1Id] = []
        masterIndex.rawChildren[lvl1Id] = []
        lvl2.forEach(lvl2Id => {
            const lvl1Lvl2Id = `${lvl1Id}-${lvl2Id}`
            masterIndex.children[lvl1Lvl2Id] = []
            masterIndex.rawChildren[lvl1Lvl2Id] = []
        })
    })

    const graph = new Graph({
        multi: false,
        allowSelfLoops: false,
        type: 'directed'
    })


    dependencies.forEach(({ from, to }) => {
        graph.updateNode(from)
        graph.updateNode(to)
        graph.updateEdge(from, to)
    })

    if (hasCycle(graph)) {
        alert('CirC!')
    }


    const timelineStart = DateTime.local(...startRaw)
    let currLvl1 = ''
    let currLvl2 = ''
    let extendedIds: string[] = []
    // CREATE RAW DICT for Extended
    rawOrder.forEach(id => {
        const raw = rawData[id]
        const spec = specDict[id]

        // If no currLvl1 - check if now is a leveranse
        // If currLvl1 - check if changed leveranse or different currLvl1 - otherwise register

        if (!currLvl1) {
            if (spec && spec.lvl1) {
                if (spec.clss !== 'leveranse' || !spec.lvl2) {
                    console.log('error1', raw)
                }
                else {
                    currLvl1 = spec.lvl1
                    currLvl2 = spec.lvl2
                    masterIndex.rawChildren[currLvl1].push(id)
                }
            }
        }
        else {
            if (spec && spec.clss === 'leveranse') {
                if (!spec.lvl1 || !spec.lvl2) {
                    console.log('error2', raw)
                }
                else {
                    currLvl1 = spec.lvl1
                    currLvl2 = spec.lvl2
                    masterIndex.rawChildren[currLvl1].push(id)
                }
            }
            else {
                if (spec && spec.lvl1 === currLvl1 && spec.lvl2 === currLvl2) {
                    masterIndex.rawChildren[currLvl1].push(id)
                    masterIndex.rawChildren[`${currLvl1}-${currLvl2}`].push(id)
                }
                else if (raw.id.includes(currLvl1)) {
                    const end = raw.end instanceof Date ? DateTime.fromJSDate(raw.end) : DateTime.fromISO(raw.end)
                    // Exclude activities and milestones ending before start of timeline, for unmapped items.
                    if (timelineStart < end && !raw.desc.includes('CANCELED')) {
                        extendedIds.push(id)
                        masterIndex.rawChildren[currLvl1].push(id)
                        masterIndex.rawChildren[`${currLvl1}-${currLvl2}`].push(id)
                    }

                }
                else {
                    currLvl1 = ''
                    currLvl2 = ''
                }
            }
        }


    })


    // CREATE ELEMENTS
    Object.entries(specDict).forEach(([id, spec]) => {
        if (rawData[id]) {
            const raw = rawData[id]
            elements[id] = {
                id: id,
                desc: raw.desc,
                start: raw.start instanceof Date ? DateTime.fromJSDate(raw.start).toISO()! : raw.start,
                end: raw.end instanceof Date ? DateTime.fromJSDate(raw.end).toISO()! : raw.end,
                progress: raw.progress,
                actual: raw.actual,
                estimated: raw.estimated,
                ...spec
            }
        }
        else {
            masterIndex.filters['specIdsUnmatched'].push(id)

            elements[id] = {
                id: id,
                desc: '',
                start: '',
                end: '',
                progress: 'unknown',
                actual: 0,
                estimated: 0,
                ...spec,
            }
        }
    })

    // POPULATE INDEXES
    Object.entries(elements).forEach(([id, el]) => {

        // Index by class
        masterIndex.clss[el.clss].push(id)

        if (el.eie && el.clss !== 'eierskifte') {
            masterIndex.eierskifte[el.eie].push(id)
        }

        if (el.clss === 'ist') {
            masterIndex.inbounds[el.id] = []
            graph.forEachInNeighbor(id, (id2) => {
                masterIndex.inbounds[el.id].push(id2)
                if (!masterIndex.filters.istPlanRelated.includes(id2)) {
                    masterIndex.filters.istPlanRelated.push(id2)
                }
                if (!masterIndex.filters.satPlan.includes(id2)) {
                    masterIndex.filters.satPlan.push(id2)
                }
            })
        }

        if (el.clss === 'sat-done') {
            if (!masterIndex.filters.satPlan.includes(id)) {
                masterIndex.filters.satPlan.push(id)
            }
        }

        if (el.lvl1 !== undefined) {
            masterIndex.children[el.lvl1].push(id)
            if (el.lvl2 !== undefined && el.clss !== 'leveranse') {
                const lvl1Lvl2Id = `${el.lvl1}-${el.lvl2}`
                masterIndex.children[lvl1Lvl2Id].push(id)
            }
        }
    })

    // Make sure all elements connected to satplan are loaded as elements, even if they are not specced
    masterIndex.filters.satPlan.forEach(id => {
        if (!(id in elements)) {
            if (rawData[id]) {
                const raw = rawData[id]
                elements[id] = {
                    id: id,
                    desc: raw.desc,
                    start: raw.start instanceof Date ? DateTime.fromJSDate(raw.start).toISO()! : raw.start,
                    end: raw.end instanceof Date ? DateTime.fromJSDate(raw.end).toISO()! : raw.end,
                    progress: raw.progress,
                    actual: raw.actual,
                    estimated: raw.estimated,
                    clss: 'generic',
                }
            }
            else {
                elements[id] = {
                    id: id,
                    desc: '',
                    start: '',
                    end: '',
                    progress: 'unknown',
                    actual: 0,
                    estimated: 0,
                    clss: 'generic',
                }
            }

        }
    })

    // Make sure all extended elements are loaded, even if they are not specced
    extendedIds.forEach(id => {
        if (!(id in elements)) {
            if (rawData[id]) {
                const raw = rawData[id]
                elements[id] = {
                    id: id,
                    desc: raw.desc,
                    start: raw.start instanceof Date ? DateTime.fromJSDate(raw.start).toISO()! : raw.start,
                    end: raw.end instanceof Date ? DateTime.fromJSDate(raw.end).toISO()! : raw.end,
                    progress: raw.progress,
                    actual: raw.actual,
                    estimated: raw.estimated,
                    clss: 'generic',
                }
            }
            else {
                elements[id] = {
                    id: id,
                    desc: '',
                    start: '',
                    end: '',
                    progress: 'unknown',
                    actual: 0,
                    estimated: 0,
                    clss: 'generic',
                }
            }

        }
    })

    return {
        elements,
        masterIndex,
    }

}