import { DateTime } from "luxon";
import { ProgressState, RawLineDict } from "../types.d";
import getInterpolatedLinearFactorBetweenDates from "./getInterpolatedLinearFactorBetweenDates";
import getLastCutoff from "./getLastCutoff";


export default function processRawData(input: any[]): { data: RawLineDict, order: any[] } {

    let order: any = []
    let lineDict: RawLineDict = {}
    const today = DateTime.now()
    const lastCutoff = getLastCutoff(today)


    input.forEach((row: any) => {
        let ret: any = {}

        // Clean up date objects
        Object.entries(row).forEach(([key, val]) => {
            ret[key] = val instanceof Date ? DateTime.fromJSDate(val).toISO()! : val
        })

        // Set progresStatus
        const start = DateTime.fromJSDate(row['start'])
        const end = DateTime.fromJSDate(row['end'])
        const actual = row['actual'] as number
        const factor = getInterpolatedLinearFactorBetweenDates(start, end, lastCutoff)

        const progress: ProgressState = actual === 1 ? 'done'
            : factor === 1 ? 'late'
                : factor === 0 ? 'ahead'
                    : factor > actual ? 'lagging' : 'ahead'

        ret['estimated'] = factor
        ret['progress'] = progress


        lineDict[row.seq] = ret
        order.push(row.seq)
    })

    return {
        data: lineDict,
        order
    }

}