import { Box, Button, Stack, Textarea } from "@chakra-ui/react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { setSpec } from "../../slice"
import { SpecLineDict } from "../../types.d"
import { footerBarHeight } from "./FooterBar"



export const inSpecWidth = 1200


export default function SpecInsert() {

    let [value, setValue] = useState('')
    const dispatch = useDispatch()

    let handleInputChange = (e: any) => {
        let inputValue = e.target.value
        setValue(inputValue)
    }

    const effectuateInsertSpec = () => {
        if (value.length !== 0) {
            const newSpec = JSON.parse(value) as SpecLineDict
            dispatch(setSpec(newSpec))
            setValue('')
        }
    }

    return (
        <Box
            bg="whiteAlpha.800"
            position={"fixed"}
            right="0"
            bottom={`${footerBarHeight}px`}
            width={`${inSpecWidth}px`}
            // height={"45vh"}
            shadow="xl"
            // overflow={"scroll"}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="3"
            p="2"
            m="2"
        >


            <Stack>
                <Stack
                    border="1px solid"
                    borderColor="gray.400"
                    p="3"
                    // height={"40vh"}
                >

                    <Textarea
                        value={value}
                        onChange={handleInputChange}
                        placeholder='{ ... }'
                        size='sm'
                    />
                    <Button
                    onClick={effectuateInsertSpec}
                    >Insert Spec</Button>

                </Stack>

            </Stack>



        </Box>
    )


}