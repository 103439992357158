import { Button, Select, Stack, Text } from "@chakra-ui/react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectRawData, selectSpec, setSpec } from "../../slice"
import { Eierskifte, ElementClass, Lvl1, Lvl2, eierskifter, elementClass, lvl1, lvl2 } from "../../types.d"



export default function GridRowSelected(props: { id: string }) {

    const { id } = props
    const rawData = useSelector(selectRawData)
    const specData = useSelector(selectSpec)
    const dispatch = useDispatch()

    const row = rawData[id]
    const specLine = specData[id] || {}

    const [clss, setClss] = useState(specLine.clss)
    const [lvl1Id, setLvl1Id] = useState(specLine.lvl1)
    const [lvl2Id, setLvl2Id] = useState(specLine.lvl2)
    const [lane, setLane] = useState(specLine.lane)
    const [eie, setEie] = useState(specLine.eie)


    const effectuateChange = () => {
        dispatch(setSpec({
            ...specData,
            [id]: {
                ...specData[id],
                clss: clss,
                lvl1: lvl1Id,
                lvl2: lvl2Id,
                lane: lane,
                eie: eie
            }
        }))
    }

    const handleChange = () => {

        if (!clss) {
            alert('Missing class')
        }

        else if (lvl1Id && lvl2Id && clss && clss === 'leveranse') {
            // Dette er selve leveransen, men sjekk at den ikke allerede eksisterer.

            const conflicts = Object.entries(specData).filter(([k, v]) => v.lvl1 === lvl1Id && v.lvl2 === lvl2Id && v.clss === 'leveranse' && k !== id)
            if (conflicts.length > 0) {
                alert('Leveranse finnes allerede')
            }
            else {
                effectuateChange()
            }
        }

        else if (lvl2Id && !lvl1Id) {
            alert('Mangler toppnivå')
        }

        else if (lvl1Id && !lvl2Id && clss && clss === 'leveranse') {
            alert('Mangler leveransekode for leveranse')
        }

        else if (clss && clss === 'eierskifte') {
            const conflicts = Object.entries(specData).filter(([k, v]) => v.eie === eie && v.clss === 'eierskifte' && k !== id)
            if (conflicts.length > 0) {
                alert('Eierskifte finnes allerede')
            }
            else {
                effectuateChange()
            }
        }

        else {
            effectuateChange()
        }

    }


    // const handleClear = () => {
    //     setClss(specLine.clss)
    //     setLvl1Id(specLine.lvl1)
    //     setLvl2Id(specLine.lvl2)
    //     setLane(specLine.lane)
    // }

    const handleDelete = () => {
            const { [id]: out, ...remaining } = specData
            dispatch(setSpec(remaining))
    }


    return (

        <Stack
            direction="row"
            align="center"
            w="100%"
            justify={"space-between"}

        >
            <Stack
                w="80%">
                <Text>{row.id}</Text>
                <Text>{row.desc}</Text>
            </Stack>


            <Stack
                direction="row"
                align="center"
                w="100%"
            >
                {/* <Stack justify="center" p="1rem" h="100%">
                    <Text fontSize="sm" fontWeight={600}>ID</Text>
                </Stack> */}
                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>Class</Text>
                    <ElementClassSelect id={row.id} value={clss} callback={setClss} inEdit={specLine.clss !== clss} />
                </Stack>

                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>Lvl1</Text>
                    <ElementLvl1Select id={row.id} value={lvl1Id} callback={setLvl1Id} inEdit={specLine.lvl1 !== lvl1Id} />
                </Stack>

                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>Lvl2</Text>
                    <ElementLvl2Select id={row.id} value={lvl2Id} callback={setLvl2Id} inEdit={specLine.lvl2 !== lvl2Id} />
                </Stack>

                {/* <Stack justify="center" p="1rem" h="100%" borderLeft={"2px solid"} borderColor="gray.400">
                    <Text fontSize="sm" fontWeight={600}>Relation</Text>
                </Stack> */}

                {/* 
                <Stack justify="center" p="1rem" h="100%" borderLeft={"2px solid"} borderColor="gray.400">
                    <Text fontSize="sm" fontWeight={600}>Position</Text>
                </Stack> */}

                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>Lane</Text>
                    <ElementLaneSelect id={row.id} value={lane} callback={setLane} inEdit={specLine.lane !== lane} />
                </Stack>

                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>Eie</Text>
                    <ElementEieSelect id={row.id} value={eie} callback={setEie} inEdit={specLine.eie !== eie} />
                </Stack>

                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>&nbsp;</Text>
                    <Button size="sm" onClick={handleChange}>Submit</Button>
                </Stack>

                {/* <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>&nbsp;</Text>
                    <Button size="sm" onClick={handleClear} variant="ghost">Clear</Button>
                </Stack> */}

                <Stack
                    spacing="0"
                >
                    <Text fontSize="sm" fontWeight={600}>&nbsp;</Text>
                    <Button size="sm" onClick={handleDelete} variant="ghost" >Delete</Button>
                </Stack>

            </Stack>
        </Stack>





    )

}




export function ElementClassSelect(props: {
    id: string,
    value: ElementClass,
    callback: (clss: ElementClass) => void,
    inEdit: boolean,
}) {

    const { value, callback, inEdit } = props

    return (
        <Select
            name="clss"
            value={value}
            onChange={e => callback(e.target.value as ElementClass)}
            title="Class"
            bg={inEdit ? 'yellow.100' : value === undefined ? 'gray.100' : undefined}

        // placeholder="Class"
        >
            <option value=""></option>
            {elementClass.map(clss => {
                return <option
                    key={clss}
                    value={clss}>{clss.toUpperCase()}</option>
            })}

        </Select>
    )

}


export function ElementLaneSelect(props: {
    id: string,
    value: number | undefined,
    callback: (lane: number) => void,
    inEdit: boolean,
}) {

    const { value, callback, inEdit } = props

    return (
        <Select
            name="lane"
            value={value}
            onChange={e => callback(parseInt(e.target.value))}
            title="Lane"
            bg={inEdit ? 'yellow.100' : value === undefined ? 'gray.100' : undefined}
        // placeholder="Lane"

        >
            <option value=""></option>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(lane => {
                return <option
                    key={lane}
                    value={lane}>{lane}</option>
            })}

        </Select>
    )

}


export function ElementEieSelect(props: {
    id: string,
    value: number | undefined,
    callback: (eie: Eierskifte) => void,
    inEdit: boolean,
}) {

    const { value, callback, inEdit } = props

    return (
        <Select
            name="eie"
            value={value}
            onChange={e => callback(parseInt(e.target.value) as Eierskifte)}
            title="Eie"
            bg={inEdit ? 'yellow.100' : value === undefined ? 'gray.100' : undefined}
        // placeholder="Lane"

        >
            <option value=""></option>
            {eierskifter.map(eieId => {
                return <option
                    key={eieId}
                    value={eieId}>{eieId}</option>
            })}

        </Select>
    )

}




export function ElementLvl1Select(props: {
    id: string,
    value: Lvl1 | undefined,
    callback: (customId: Lvl1) => void,
    inEdit: boolean,
}) {

    const { value, callback, inEdit } = props

    return (
        <Select
            name="id"
            value={value}
            onChange={e => callback(e.target.value as Lvl1)}
            title="Is Lvl1"
            bg={inEdit ? 'yellow.100' : value === undefined ? 'gray.100' : undefined}
        // placeholder="Custom Id"
        >
            <option value=""></option>
            {lvl1.map(id => {
                return <option
                    key={id}
                    value={id}>{id}</option>
            })}

        </Select>
    )

}

export function ElementLvl2Select(props: {
    id: string,
    value: Lvl2 | undefined,
    callback: (customId: Lvl2) => void,
    inEdit: boolean,
}) {

    const { value, callback, inEdit } = props

    return (
        <Select
            name="id"
            value={value}
            onChange={e => callback(e.target.value as Lvl2)}
            title="Is Lvl2"
            bg={inEdit ? 'yellow.100' : value === undefined ? 'gray.100' : undefined}

        // placeholder="Custom Id"
        >
            <option value=""></option>
            {lvl2.map(id => {
                return <option
                    key={id}
                    value={id}>{id}</option>
            })}

        </Select>
    )

}