import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import processUpdateElementsAndIndexes from "../func/processUpdateElementsAndIndexes";
import { selectDependencies, selectRawData, selectRawOrder, selectSpec, selectTimelineStart, setElements, setMasterIndex } from "../slice";



export default function useUpdateElements() {

    const spec = useSelector(selectSpec)
    const rawData = useSelector(selectRawData)
    const rawOrder = useSelector(selectRawOrder)
    const deps = useSelector(selectDependencies)
    const timelineStart = useSelector(selectTimelineStart)
    const dispatch = useDispatch()

    useEffect(() => {

        if (Object.keys(rawData).length > 0 && spec) {

            const {
                elements,
                masterIndex,
            } = processUpdateElementsAndIndexes(rawData, rawOrder, spec, deps, timelineStart)

            dispatch(setElements(elements))
            dispatch(setMasterIndex(masterIndex))

        }

    }, [dispatch, spec, rawData, deps])


    return null

}

