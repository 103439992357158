import { SpecLineDict } from "../types.d";


export const spec: SpecLineDict = {
  "297": {
    "clss": "kib",
    "alt": "KIB 2+3",
    "lane": 2
  },
  "1007": {
    "clss": "generic",
    "eie": 3
  },
  "7501": {
    "clss": "u7c",
    "lane": 1,
    "alt": "PAS/EPJ"
  },
  "7502": {
    "clss": "u7c",
    "lane": 2,
    "alt": "Tilpasninger kurve m/ lukket legemiddelsløyfe"
  },
  "7503": {
    "clss": "u7c",
    "lane": 3,
    "alt": "Helselogistikk"
  },
  "7504": {
    "clss": "u7c",
    "lane": 4,
    "alt": "Laboratoriesystemer"
  },
  "7505": {
    "clss": "u7c",
    "lane": 5,
    "alt": "Programvare for stråleterapi"
  },
  "7506": {
    "clss": "u7c",
    "lane": 6,
    "alt": "Sporingssystem for sterilsentral"
  },
  "7507": {
    "clss": "u7c",
    "lane": 7,
    "alt": "Bildediagnostikk"
  },
  "7509": {
    "clss": "u7c",
    "lane": 9,
    "alt": "Med. kreftbehandling"
  },
  "7510": {
    "clss": "u7c",
    "lane": 10,
    "alt": "Matforsyning"
  },
  "7511": {
    "clss": "u7c",
    "lane": 11,
    "alt": "BIM/FDV"
  },
  "7513": {
    "clss": "u7c",
    "lane": 11,
    "alt": "Multimediarkiv"
  },
  "7514": {
    "clss": "u7c",
    "lane": 12,
    "alt": "Sporing og lokalisering"
  },
  "7516": {
    "clss": "u7c",
    "lane": 13,
    "alt": "Mobil arbeidsflate"
  },
  "7591": {
    "clss": "u7c",
    "lane": 14,
    "alt": "Øvrige systemtilpasninger"
  },
  "11460": {
    "clss": "eierskifte",
    "alt": "Eierskifte 3",
    "lane": 2,
    "eie": 3
  },
  "11462": {
    "clss": "eierskifte",
    "alt": "Eierskifte 1",
    "eie": 1
  },
  "16417": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L04",
    "lane": 1
  },
  "16425": {
    "clss": "sat-done",
    "lvl1": "7501",
    "lvl2": "L05",
    "lane": 1
  },
  "16449": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7502",
    "lvl2": "L05"
  },
  "16474": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 0
  },
  "16490": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7505",
    "lvl2": "L01"
  },
  "16491": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7505",
    "lvl2": "L01"
  },
  "16494": {
    "clss": "generic",
    "lane": 4,
    "lvl1": "7505",
    "lvl2": "L01"
  },
  "16507": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 3
  },
  "16512": {
    "clss": "sat-do",
    "lvl1": "7506",
    "lvl2": "L04",
    "lane": 4
  },
  "16513": {
    "clss": "st-sit-plan",
    "lvl1": "7506",
    "lvl2": "L04",
    "lane": 1
  },
  "16522": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 1
  },
  "16523": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 1
  },
  "16524": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L01"
  },
  "16560": {
    "clss": "st-sit-done",
    "lvl1": "7509",
    "lvl2": "L01",
    "lane": 1
  },
  "16574": {
    "clss": "generic",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 0
  },
  "16575": {
    "clss": "st-sit-done",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 1
  },
  "16618": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 2
  },
  "16619": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 1
  },
  "16620": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 0
  },
  "16621": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 1
  },
  "16622": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 2
  },
  "16623": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 6
  },
  "16624": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 3
  },
  "16625": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 4
  },
  "16626": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 5
  },
  "16634": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L04",
    "lane": 1
  },
  "16636": {
    "clss": "st-sit-plan",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 0
  },
  "16637": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L07",
    "lane": 3
  },
  "16670": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L13",
    "lane": 2
  },
  "42104": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 2
  },
  "42105": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 0
  },
  "42273": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 1
  },
  "42279": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 1
  },
  "47001": {
    "clss": "eierskifte",
    "alt": "Eierskifte 2",
    "lane": 2,
    "eie": 2
  },
  "47002": {
    "clss": "kib",
    "alt": "KIB 1"
  },
  "49757": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7516",
    "lvl2": "L03"
  },
  "49758": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L01"
  },
  "49759": {
    "clss": "st-sit-plan",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 0
  },
  "49760": {
    "clss": "st-sit-done",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 1
  },
  "50937": {
    "clss": "sat-done",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 6
  },
  "50938": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 3
  },
  "60143": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 2
  },
  "61961": {
    "clss": "eierskifte",
    "alt": "Eierskifte 0"
  },
  "76691": {
    "clss": "generic",
    "eie": 1
  },
  "76916": {
    "clss": "generic",
    "eie": 2
  },
  "76977": {
    "clss": "generic",
    "eie": 2
  },
  "77037": {
    "clss": "generic",
    "eie": 3
  },
  "77099": {
    "clss": "generic",
    "eie": 3
  },
  "77153": {
    "clss": "generic",
    "eie": 3
  },
  "77216": {
    "clss": "generic",
    "eie": 3
  },
  "80702": {
    "clss": "generic",
    "eie": 2
  },
  "80714": {
    "clss": "generic",
    "eie": 3
  },
  "80735": {
    "clss": "generic",
    "eie": 1
  },
  "80769": {
    "clss": "generic",
    "eie": 1
  },
  "80773": {
    "clss": "generic",
    "eie": 1
  },
  "95324": {
    "clss": "generic",
    "eie": 2
  },
  "95325": {
    "clss": "generic",
    "eie": 2
  },
  "95454": {
    "clss": "generic",
    "eie": 2
  },
  "95469": {
    "clss": "generic",
    "eie": 3
  },
  "95471": {
    "clss": "generic",
    "eie": 3
  },
  "95484": {
    "clss": "generic",
    "eie": 3
  },
  "95523": {
    "clss": "sat-done",
    "lane": 1,
    "lvl1": "7502",
    "lvl2": "L04"
  },
  "95526": {
    "clss": "generic",
    "lane": 0,
    "lvl1": "7502",
    "lvl2": "L01"
  },
  "95528": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7502",
    "lvl2": "L01"
  },
  "95537": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L06",
    "lane": 1
  },
  "95553": {
    "clss": "st-sit-done",
    "lane": 4,
    "lvl1": "7503",
    "lvl2": "L03"
  },
  "95562": {
    "clss": "sat-plan",
    "lvl1": "7501",
    "lvl2": "L05",
    "lane": 0
  },
  "95566": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L01",
    "lane": 3
  },
  "95575": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L01",
    "lane": 0
  },
  "95578": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L01",
    "lane": 2
  },
  "95584": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L01",
    "lane": 1
  },
  "95591": {
    "clss": "ist",
    "lane": 3,
    "eie": 1
  },
  "95592": {
    "clss": "ist",
    "lane": 1,
    "eie": 1
  },
  "95593": {
    "clss": "ist",
    "lane": 2,
    "eie": 1
  },
  "95594": {
    "clss": "ist",
    "lane": 0,
    "eie": 1
  },
  "95596": {
    "clss": "ist",
    "lane": 8,
    "eie": 2
  },
  "95597": {
    "clss": "ist",
    "lane": 4,
    "eie": 2
  },
  "95598": {
    "clss": "ist",
    "lane": 7,
    "eie": 2
  },
  "95599": {
    "clss": "ist",
    "lane": 5,
    "eie": 2
  },
  "95600": {
    "clss": "ist",
    "lane": 6,
    "eie": 2
  },
  "95602": {
    "clss": "ist",
    "lane": 11,
    "eie": 3
  },
  "95603": {
    "clss": "ist",
    "lane": 12,
    "eie": 3
  },
  "95604": {
    "clss": "ist",
    "lane": 10,
    "eie": 3
  },
  "95605": {
    "clss": "ist",
    "lane": 9,
    "eie": 3
  },
  "95606": {
    "clss": "ist",
    "lane": 13,
    "eie": 3
  },
  "95607": {
    "clss": "ist",
    "lane": 14,
    "eie": 3
  },
  "95628": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 2
  },
  "95629": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 3
  },
  "95630": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 0
  },
  "95631": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 1
  },
  "95637": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 0
  },
  "95645": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 0
  },
  "95646": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 1
  },
  "95647": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 2
  },
  "95648": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 0
  },
  "95650": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 1
  },
  "95654": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 2
  },
  "95658": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 0
  },
  "95683": {
    "clss": "vit",
    "lane": 0
  },
  "95684": {
    "clss": "vit",
    "lane": 1
  },
  "95685": {
    "clss": "vit",
    "lane": 2
  },
  "95686": {
    "clss": "vit",
    "lane": 3
  },
  "95687": {
    "clss": "vit",
    "lane": 5
  },
  "95688": {
    "clss": "vit",
    "lane": 6
  },
  "95689": {
    "clss": "vit",
    "lane": 7
  },
  "95690": {
    "clss": "vit",
    "lane": 8
  },
  "95691": {
    "clss": "vit",
    "lane": 9
  },
  "95692": {
    "clss": "vit",
    "lane": 10
  },
  "95693": {
    "clss": "vit",
    "lane": 11
  },
  "95694": {
    "clss": "vit",
    "lane": 12
  },
  "95695": {
    "clss": "vit",
    "lane": 13
  },
  "95696": {
    "clss": "vit",
    "lane": 14
  },
  "95697": {
    "clss": "vit",
    "lane": 4
  },
  "95707": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 1
  },
  "95708": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 2
  },
  "95709": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L01"
  },
  "95710": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 3
  },
  "95711": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 4
  },
  "95743": {
    "clss": "ld-ros",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 0
  },
  "95860": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L04",
    "lane": 2
  },
  "95861": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L04",
    "lane": 2
  },
  "95862": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L04",
    "lane": 3
  },
  "96131": {
    "clss": "st-sit-plan",
    "lvl1": "7502",
    "lvl2": "L03",
    "lane": 0
  },
  "96250": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 1
  },
  "96251": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 0
  },
  "96252": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 5
  },
  "96264": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 4
  },
  "96265": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L03",
    "lane": 1
  },
  "96268": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 2
  },
  "96271": {
    "clss": "sat-plan",
    "lvl1": "7506",
    "lvl2": "L04",
    "lane": 3
  },
  "96309": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 4
  },
  "96327": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L01",
    "lane": 1
  },
  "96335": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L14"
  },
  "96341": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L13",
    "lane": 1
  },
  "96349": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L13",
    "lane": 3
  },
  "96351": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L14",
    "lane": 0
  },
  "96366": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L14",
    "lane": 2
  },
  "96372": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L13",
    "lane": 0
  },
  "96374": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L13",
    "lane": 0
  },
  "96375": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L14",
    "lane": 1
  },
  "96391": {
    "clss": "generic",
    "lane": 3,
    "lvl1": "7514",
    "lvl2": "L04"
  },
  "96396": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L07",
    "lane": 1
  },
  "96397": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7514",
    "lvl2": "L01"
  },
  "96398": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7514",
    "lvl2": "L01"
  },
  "96399": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L02"
  },
  "96400": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L03",
    "lane": 3
  },
  "96401": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L03"
  },
  "96402": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7514",
    "lvl2": "L03"
  },
  "96471": {
    "clss": "generic",
    "lane": 4,
    "lvl1": "7514",
    "lvl2": "L04"
  },
  "96472": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L01",
    "lane": 2
  },
  "96473": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7514",
    "lvl2": "L05"
  },
  "96474": {
    "clss": "generic",
    "lane": 3,
    "lvl1": "7514",
    "lvl2": "L05"
  },
  "96477": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7514",
    "lvl2": "L05"
  },
  "96478": {
    "clss": "sat-done",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 8
  },
  "96480": {
    "clss": "st-sit-do",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 2
  },
  "96485": {
    "clss": "sat-plan",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 7
  },
  "96486": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L07",
    "lane": 2
  },
  "96498": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L02",
    "lane": 2
  },
  "96499": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L02",
    "lane": 1
  },
  "96501": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L03",
    "lane": 1
  },
  "96515": {
    "clss": "sat-plan",
    "lane": 3,
    "lvl1": "7502",
    "lvl2": "L02"
  },
  "96516": {
    "clss": "sat-done",
    "lane": 4,
    "lvl1": "7502",
    "lvl2": "L02"
  },
  "96615": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 1
  },
  "96617": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 3
  },
  "96618": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 2
  },
  "96627": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L14",
    "lane": 1
  },
  "96632": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 0
  },
  "96633": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 1
  },
  "96637": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L03",
    "lane": 0
  },
  "96646": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L03",
    "lane": 0
  },
  "96667": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 2
  },
  "96669": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L04",
    "lane": 0
  },
  "96672": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L04",
    "lane": 0
  },
  "96681": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L04",
    "lane": 0
  },
  "96685": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L05",
    "lane": 0
  },
  "96687": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L05",
    "lane": 2
  },
  "96688": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L05",
    "lane": 1
  },
  "96701": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 1
  },
  "96703": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 4
  },
  "96704": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 3
  },
  "96706": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 6
  },
  "96717": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 1
  },
  "96720": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 0
  },
  "96722": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 3
  },
  "96725": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 0
  },
  "96732": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L01",
    "lane": 0
  },
  "96733": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 0
  },
  "96735": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 1
  },
  "96736": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 2
  },
  "96738": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 5
  },
  "96741": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 3
  },
  "96743": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 6
  },
  "96749": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L09"
  },
  "96751": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 2
  },
  "96752": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 4
  },
  "96754": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 5
  },
  "96757": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L09"
  },
  "96759": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 1
  },
  "96780": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L10"
  },
  "96782": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 0
  },
  "96783": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 1
  },
  "96785": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 3
  },
  "96788": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 1
  },
  "96790": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 2
  },
  "96791": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 0
  },
  "96794": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 3
  },
  "96795": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 0
  },
  "96797": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 2
  },
  "96800": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 3
  },
  "96804": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 1
  },
  "96805": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 0
  },
  "96806": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 1
  },
  "96817": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L12",
    "lane": 2
  },
  "96818": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L12",
    "lane": 3
  },
  "96823": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L12",
    "lane": 0
  },
  "96830": {
    "clss": "st-sit-done",
    "lvl1": "7501",
    "lvl2": "L03",
    "lane": 3
  },
  "96834": {
    "clss": "st-sit-done",
    "lvl1": "7502",
    "lvl2": "L03",
    "lane": 1
  },
  "96839": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7503",
    "lvl2": "L02"
  },
  "96841": {
    "clss": "st-sit-done",
    "lane": 3,
    "lvl1": "7503",
    "lvl2": "L02"
  },
  "96843": {
    "clss": "generic",
    "lane": 0,
    "lvl1": "7503",
    "lvl2": "L02"
  },
  "96848": {
    "clss": "st-sit-plan",
    "lane": 1,
    "lvl1": "7503",
    "lvl2": "L02"
  },
  "96849": {
    "clss": "st-sit-done",
    "lane": 1,
    "lvl1": "7503",
    "lvl2": "L02"
  },
  "96850": {
    "clss": "sat-done",
    "lane": 3,
    "lvl1": "7503",
    "lvl2": "L04"
  },
  "96857": {
    "clss": "st-sit-done",
    "lane": 3,
    "lvl1": "7503",
    "lvl2": "L03"
  },
  "96863": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7503",
    "lvl2": "L03"
  },
  "96864": {
    "clss": "st-sit-plan",
    "lvl1": "7503",
    "lvl2": "L03"
  },
  "96866": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L03",
    "lane": 2
  },
  "96868": {
    "clss": "sat-done",
    "lane": 1,
    "lvl1": "7503",
    "lvl2": "L04"
  },
  "96870": {
    "clss": "sat-plan",
    "lane": 0,
    "lvl1": "7503",
    "lvl2": "L04"
  },
  "96871": {
    "clss": "sat-done",
    "lane": 2,
    "lvl1": "7503",
    "lvl2": "L04"
  },
  "96881": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L02",
    "lane": 2
  },
  "96934": {
    "clss": "sat-plan",
    "lane": 0,
    "lvl1": "7502",
    "lvl2": "L04"
  },
  "96958": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7516",
    "lvl2": "L01"
  },
  "96959": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7516",
    "lvl2": "L01"
  },
  "96960": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L02"
  },
  "96961": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7516",
    "lvl2": "L02"
  },
  "96962": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7516",
    "lvl2": "L02"
  },
  "96963": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L03"
  },
  "96964": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7516",
    "lvl2": "L04"
  },
  "96965": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L04"
  },
  "96967": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L05"
  },
  "96968": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L06"
  },
  "96969": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L06",
    "lane": 1
  },
  "96970": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L06",
    "lane": 2
  },
  "96971": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L07",
    "lane": 1
  },
  "96972": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L07"
  },
  "96973": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L08",
    "lane": 0
  },
  "96977": {
    "clss": "st-sit-plan",
    "lvl1": "7501",
    "lvl2": "L03",
    "lane": 2
  },
  "96988": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 0
  },
  "97144": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L02",
    "lane": 0
  },
  "97146": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L02",
    "lane": 1
  },
  "97148": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L02",
    "lane": 2
  },
  "97149": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L02",
    "lane": 4
  },
  "97157": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L02",
    "lane": 3
  },
  "97159": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 1
  },
  "97160": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 2
  },
  "97161": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 3
  },
  "97162": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 4
  },
  "97170": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L04"
  },
  "97172": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 1
  },
  "97173": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 0
  },
  "97174": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 2
  },
  "97175": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 3
  },
  "97183": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 0
  },
  "97185": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 2
  },
  "97186": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 4
  },
  "97187": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 3
  },
  "97197": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 0
  },
  "97204": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 1
  },
  "97205": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 0
  },
  "97208": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 0
  },
  "97210": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 1
  },
  "97212": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 2
  },
  "97213": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 2
  },
  "97221": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 0
  },
  "97222": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 0
  },
  "97224": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 1
  },
  "97225": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 0
  },
  "97227": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 1
  },
  "97229": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 2
  },
  "97230": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 2
  },
  "97238": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L09"
  },
  "97240": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 1
  },
  "97241": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 0
  },
  "97243": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 0
  },
  "97245": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 1
  },
  "97246": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 1
  },
  "97254": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 0
  },
  "97272": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 1
  },
  "97273": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 0
  },
  "97275": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 1
  },
  "97277": {
    "clss": "st-sit-done",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 2
  },
  "97278": {
    "clss": "sat-done",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 2
  },
  "97286": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 0
  },
  "97299": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L12",
    "lane": 0
  },
  "97300": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L12",
    "lane": 2
  },
  "97301": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L12",
    "lane": 1
  },
  "97331": {
    "clss": "generic",
    "eie": 1
  },
  "97346": {
    "clss": "generic",
    "eie": 2
  },
  "97347": {
    "clss": "generic",
    "eie": 3
  },
  "97353": {
    "clss": "generic",
    "eie": 2
  },
  "97373": {
    "clss": "generic",
    "eie": 1
  },
  "97374": {
    "clss": "generic",
    "eie": 1
  },
  "97384": {
    "clss": "generic",
    "eie": 2
  },
  "97394": {
    "clss": "generic",
    "eie": 3
  },
  "97416": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7514",
    "lvl2": "L03"
  },
  "97418": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7502",
    "lvl2": "L06"
  },
  "97419": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7502",
    "lvl2": "L06"
  },
  "97420": {
    "clss": "generic",
    "lane": 5,
    "lvl1": "7502",
    "lvl2": "L06"
  },
  "97421": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 2
  },
  "97422": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 3
  },
  "97423": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 4
  },
  "97488": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 0
  },
  "97489": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 8
  },
  "97491": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 0
  },
  "97501": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 1
  },
  "97502": {
    "clss": "generic",
    "lane": 3,
    "lvl1": "7502",
    "lvl2": "L06"
  },
  "97503": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 3
  },
  "97504": {
    "clss": "sat-do",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 2
  },
  "97505": {
    "clss": "sat-do",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 6
  },
  "97507": {
    "clss": "generic",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 1
  },
  "97509": {
    "clss": "generic",
    "lane": 4,
    "lvl1": "7502",
    "lvl2": "L06"
  },
  "97548": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L03",
    "lane": 3
  },
  "97549": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L03",
    "lane": 2
  },
  "97550": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L03",
    "lane": 1
  },
  "97551": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L03"
  },
  "97552": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L03",
    "lane": 0
  },
  "97553": {
    "clss": "test-do",
    "lane": 1,
    "lvl1": "7502",
    "lvl2": "L02"
  },
  "97568": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 5
  },
  "97595": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L01",
    "lane": 1
  },
  "97596": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L01",
    "lane": 2
  },
  "97597": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L02",
    "lane": 2
  },
  "97598": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7505",
    "lvl2": "L02"
  },
  "97599": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L02",
    "lane": 3
  },
  "97600": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L02",
    "lane": 1
  },
  "97602": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7505",
    "lvl2": "L02"
  },
  "97603": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L02"
  },
  "97604": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L02",
    "lane": 3
  },
  "97607": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 5
  },
  "97609": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 8
  },
  "97613": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 7
  },
  "97614": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 3
  },
  "97615": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 10
  },
  "97619": {
    "clss": "generic",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 4
  },
  "97626": {
    "clss": "sat-done",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 7
  },
  "97627": {
    "clss": "generic",
    "lvl1": "7509",
    "lvl2": "L01",
    "lane": 1
  },
  "97628": {
    "clss": "generic",
    "lvl1": "7509",
    "lvl2": "L01",
    "lane": 1
  },
  "97631": {
    "clss": "sat-done",
    "lvl1": "7509",
    "lvl2": "L01",
    "lane": 2
  },
  "97633": {
    "clss": "generic",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 0
  },
  "97634": {
    "clss": "generic",
    "lvl1": "7510",
    "lvl2": "L01"
  },
  "97635": {
    "clss": "generic",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 1
  },
  "97636": {
    "clss": "generic",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 0
  },
  "97638": {
    "clss": "sat-done",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 2
  },
  "97639": {
    "clss": "generic",
    "lane": 3,
    "lvl1": "7502",
    "lvl2": "L05"
  },
  "97640": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7502",
    "lvl2": "L05"
  },
  "97642": {
    "clss": "sat-done",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 9
  },
  "97644": {
    "clss": "sat-done",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 5
  },
  "97692": {
    "clss": "sat-do",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 3
  },
  "97695": {
    "clss": "sat-do",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 1
  },
  "97698": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 1
  },
  "97699": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 3
  },
  "97700": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 2
  },
  "97701": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 0
  },
  "97702": {
    "clss": "generic",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 0
  },
  "97713": {
    "clss": "generic",
    "lane": 2,
    "lvl1": "7514",
    "lvl2": "L04"
  },
  "97714": {
    "clss": "generic",
    "lvl1": "7514",
    "lvl2": "L05"
  },
  "97726": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L01",
    "lane": 0
  },
  "97733": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L01",
    "lane": 1
  },
  "97736": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L01",
    "lane": 1
  },
  "97737": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L01",
    "lane": 1
  },
  "97772": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L05",
    "lane": 3
  },
  "97807": {
    "clss": "st-sit-done",
    "lane": 2,
    "lvl1": "7502",
    "lvl2": "L02"
  },
  "97890": {
    "clss": "generic",
    "lane": 1,
    "lvl1": "7502",
    "lvl2": "L01"
  },
  "97891": {
    "clss": "generic",
    "lane": 0,
    "lvl1": "7502",
    "lvl2": "L01"
  },
  "97892": {
    "clss": "st-sit-done",
    "lvl1": "7501",
    "lvl2": "L03",
    "lane": 3
  },
  "98034": {
    "clss": "sat-plan",
    "lvl1": "7501",
    "lvl2": "L05",
    "lane": 0
  },
  "98036": {
    "clss": "sat-done",
    "lvl1": "7501",
    "lvl2": "L05",
    "lane": 1
  },
  "98038": {
    "clss": "generic",
    "lvl1": "7501",
    "lvl2": "L04",
    "lane": 1
  },
  "98046": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 1
  },
  "98047": {
    "clss": "generic",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 5
  },
  "98221": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 2
  },
  "98222": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 1
  },
  "98223": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 2
  },
  "98225": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L02"
  },
  "98226": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 1
  },
  "98227": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 3
  },
  "98228": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 2
  },
  "98229": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 4
  },
  "98231": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 2
  },
  "98232": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 1
  },
  "98234": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 1
  },
  "98235": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 2
  },
  "98237": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 1
  },
  "98238": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 2
  },
  "98239": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 5
  },
  "98240": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 3
  },
  "98241": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 4
  },
  "98243": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L03"
  },
  "98244": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L03"
  },
  "98246": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 1
  },
  "98247": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 2
  },
  "98249": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 1
  },
  "98250": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 2
  },
  "98251": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L04"
  },
  "98252": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 3
  },
  "98253": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 4
  },
  "98255": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 1
  },
  "98256": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 2
  },
  "98258": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 1
  },
  "98259": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 2
  },
  "98261": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L05"
  },
  "98262": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 1
  },
  "98263": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 1
  },
  "98264": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L05"
  },
  "98265": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 2
  },
  "98267": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 2
  },
  "98268": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L05"
  },
  "98270": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 1
  },
  "98271": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 2
  },
  "98273": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 0
  },
  "98274": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 4
  },
  "98275": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 2
  },
  "98276": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L06"
  },
  "98277": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 1
  },
  "98279": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 1
  },
  "98280": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 3
  },
  "98282": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 1
  },
  "98283": {
    "clss": "ld-ros",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 2
  },
  "98285": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L07"
  },
  "98286": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 1
  },
  "98287": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 0
  },
  "98288": {
    "clss": "st-sit-done",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 2
  },
  "98289": {
    "clss": "sat-done",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 3
  },
  "98291": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 2
  },
  "98292": {
    "clss": "generic",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 1
  },
  "98313": {
    "clss": "st-sit-done",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 4
  },
  "98314": {
    "clss": "st-sit-do",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 3
  },
  "98315": {
    "clss": "st-sit-do",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 1
  },
  "98316": {
    "clss": "sat-done",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 9
  },
  "98318": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L05"
  },
  "98319": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L07",
    "lane": 2
  },
  "98320": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L08"
  },
  "98321": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L08",
    "lane": 1
  },
  "98322": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L09",
    "lane": 1
  },
  "98323": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L09",
    "lane": 2
  },
  "98324": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L09",
    "lane": 1
  },
  "98325": {
    "clss": "generic",
    "lvl1": "7516",
    "lvl2": "L09",
    "lane": 2
  },
  "98328": {
    "clss": "sat-plan",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 4
  },
  "98330": {
    "clss": "st-sit-done",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 2
  },
  "98331": {
    "clss": "st-sit-done",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 3
  },
  "98332": {
    "clss": "sat-done",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 5
  },
  "98333": {
    "clss": "sat-done",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 6
  },
  "98334": {
    "clss": "sat-done",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 7
  },
  "98341": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 0
  },
  "98343": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 1
  },
  "98345": {
    "clss": "generic",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 2
  },
  "98347": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 2
  },
  "98349": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 2
  },
  "98353": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 1
  },
  "98355": {
    "clss": "generic",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 0
  },
  "98357": {
    "clss": "generic",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 1
  },
  "98375": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 2
  },
  "98376": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 1
  },
  "98378": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 0
  },
  "98379": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 1
  },
  "98380": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 0
  },
  "98381": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 2
  },
  "98382": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 3
  },
  "98386": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 2
  },
  "98387": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 1
  },
  "98389": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 0
  },
  "98390": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 1
  },
  "98391": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 0
  },
  "98392": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 2
  },
  "98393": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 0
  },
  "98397": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 2
  },
  "98398": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 1
  },
  "98400": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 0
  },
  "98401": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 1
  },
  "98402": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 0
  },
  "98403": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 2
  },
  "98404": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 3
  },
  "98408": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 2
  },
  "98409": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 1
  },
  "98411": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 0
  },
  "98412": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 1
  },
  "98413": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 0
  },
  "98414": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 2
  },
  "98415": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 3
  },
  "98420": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 2
  },
  "98421": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 1
  },
  "98423": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 0
  },
  "98424": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 1
  },
  "98425": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 0
  },
  "98426": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 2
  },
  "98427": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 3
  },
  "98431": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 2
  },
  "98432": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 1
  },
  "98434": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 0
  },
  "98435": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 1
  },
  "98436": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 0
  },
  "98437": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 2
  },
  "98438": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 3
  },
  "98442": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 2
  },
  "98443": {
    "clss": "ld-ros",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 1
  },
  "98445": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 1
  },
  "98446": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 2
  },
  "98447": {
    "clss": "generic",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 0
  },
  "98448": {
    "clss": "st-sit-done",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 3
  },
  "98449": {
    "clss": "sat-done",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 0
  },
  "98457": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L03",
    "lane": 1
  },
  "98474": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 0
  },
  "98476": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 1
  },
  "98488": {
    "clss": "generic",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 2
  },
  "98490": {
    "clss": "generic",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 5
  },
  "98496": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 2
  },
  "98507": {
    "clss": "generic",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 3
  },
  "98520": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L01",
    "lane": 4
  },
  "98521": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L01",
    "lane": 1
  },
  "98522": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L01",
    "lane": 2
  },
  "98523": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L01",
    "lane": 3
  },
  "98526": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 2
  },
  "98563": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 1
  },
  "98564": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 2
  },
  "98574": {
    "clss": "st-sit-done",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 4
  },
  "98592": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 1
  },
  "98594": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 3
  },
  "98612": {
    "clss": "sat-plan",
    "lvl1": "7591",
    "lvl2": "L12",
    "lane": 1
  },
  "98622": {
    "clss": "st-sit-plan",
    "lvl1": "7591",
    "lvl2": "L12",
    "lane": 0
  },
  "98633": {
    "clss": "sat-done",
    "lvl1": "7591",
    "lvl2": "L14",
    "lane": 3
  },
  "98635": {
    "clss": "st-sit-do",
    "lvl1": "7506",
    "lvl2": "L04",
    "lane": 2
  },
  "98648": {
    "clss": "st-sit-done",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 6
  },
  "98650": {
    "clss": "st-sit-done",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 5
  },
  "98686": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L05",
    "lane": 1
  },
  "98687": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L05",
    "lane": 2
  },
  "98688": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L05",
    "lane": 3
  },
  "98689": {
    "clss": "generic",
    "lvl1": "7503",
    "lvl2": "L05",
    "lane": 4
  },
  "p_2.7.3.6": {
    "clss": "leveranse",
    "lvl1": "7502",
    "lvl2": "L01",
    "lane": 2
  },
  "p_2.7.3.7": {
    "clss": "leveranse",
    "lvl1": "7502",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.3.8": {
    "clss": "leveranse",
    "lvl1": "7502",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.2.7": {
    "clss": "leveranse",
    "lvl1": "7501",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.2.9": {
    "clss": "leveranse",
    "lvl1": "7501",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.2.11": {
    "clss": "leveranse",
    "lvl1": "7501",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.4.7": {
    "clss": "leveranse",
    "lvl1": "7503",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.4.8": {
    "clss": "leveranse",
    "lvl1": "7503",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.4.9": {
    "clss": "leveranse",
    "lvl1": "7503",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.4.11": {
    "clss": "leveranse",
    "lvl1": "7503",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.4.12": {
    "clss": "leveranse",
    "lvl1": "7503",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.5.2": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.5.3": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.5.4": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.5.5": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.5.6": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.5.7": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.5.8": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L08",
    "lane": 8
  },
  "p_2.7.5.9": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L09",
    "lane": 9
  },
  "p_2.7.5.10": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L10",
    "lane": 10
  },
  "p_2.7.5.11": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L11",
    "lane": 11
  },
  "p_2.7.5.12": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L12",
    "lane": 12
  },
  "p_2.7.5.13": {
    "clss": "leveranse",
    "lvl1": "7504",
    "lvl2": "L13",
    "lane": 13
  },
  "p_2.7.6.6": {
    "clss": "leveranse",
    "lvl1": "7505",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.6.7": {
    "clss": "leveranse",
    "lvl1": "7505",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.6.8": {
    "clss": "leveranse",
    "lvl1": "7505",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.6.9": {
    "clss": "leveranse",
    "lvl1": "7505",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.6.10": {
    "clss": "leveranse",
    "lvl1": "7505",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.7.7": {
    "clss": "leveranse",
    "lvl1": "7506",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.7.8": {
    "clss": "leveranse",
    "lvl1": "7506",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.7.9": {
    "clss": "leveranse",
    "lvl1": "7506",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.7.10": {
    "clss": "leveranse",
    "lvl1": "7506",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.8.8": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.8.9": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.8.10": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.8.11": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.8.12": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.8.13": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.8.14": {
    "clss": "leveranse",
    "lvl1": "7507",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.9.5": {
    "clss": "leveranse",
    "lvl1": "7509",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.10.5": {
    "clss": "leveranse",
    "lvl1": "7510",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.11.7": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.11.8": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.11.9": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.11.10": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.11.11": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.11.12": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.11.13": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.11.14": {
    "clss": "leveranse",
    "lvl1": "7513",
    "lvl2": "L08",
    "lane": 8
  },
  "p_2.7.12.5": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.12.6": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.12.7": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.12.8": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.12.9": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.12.10": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.12.11": {
    "clss": "leveranse",
    "lvl1": "7514",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.13.7": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L01",
    "lane": 1
  },
  "p_2.7.13.8": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.13.9": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.13.10": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.13.11": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.13.12": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.13.13": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.13.14": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L08",
    "lane": 8
  },
  "p_2.7.13.15": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L09",
    "lane": 9
  },
  "p_2.7.13.16": {
    "clss": "leveranse",
    "lvl1": "7516",
    "lvl2": "L10",
    "lane": 10
  },
  "p_2.7.14.8": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L02",
    "lane": 2
  },
  "p_2.7.14.9": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.14.10": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.14.11": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.14.12": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.14.13": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L07",
    "lane": 7
  },
  "p_2.7.14.14": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L08",
    "lane": 8
  },
  "p_2.7.14.15": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L09",
    "lane": 9
  },
  "p_2.7.14.16": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L10",
    "lane": 10
  },
  "p_2.7.14.19": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L13",
    "lane": 13
  },
  "p_2.7.14.21": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L15",
    "lane": 15
  },
  "p_2.7.14.20": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L14",
    "lane": 14
  },
  "p_2.7.14.17": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L11",
    "lane": 11
  },
  "p_2.7.14.18": {
    "clss": "leveranse",
    "lvl1": "7591",
    "lvl2": "L12",
    "lane": 12
  },
  "p_2.7.2.8": {
    "clss": "leveranse",
    "lvl1": "7501",
    "lvl2": "L03",
    "lane": 3
  },
  "p_2.7.2.10": {
    "clss": "leveranse",
    "lvl1": "7501",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.3.9": {
    "clss": "leveranse",
    "lvl1": "7502",
    "lvl2": "L04",
    "lane": 4
  },
  "p_2.7.3.10": {
    "clss": "leveranse",
    "lvl1": "7502",
    "lvl2": "L05",
    "lane": 5
  },
  "p_2.7.3.11": {
    "clss": "leveranse",
    "lvl1": "7502",
    "lvl2": "L06",
    "lane": 6
  },
  "p_2.7.4.10": {
    "clss": "leveranse",
    "lvl1": "7503",
    "lvl2": "L04",
    "lane": 4
  }
}