import { Stack, Text } from "@chakra-ui/react";
import { useDropzone } from 'react-dropzone';
import { useSelector } from "react-redux";
import { read, utils } from 'xlsx';
import { useActions } from "./hooks/useActions";
import { selectElementsLoaded } from "./slice";
import { RawLine } from "./types.d";





export default function InitUpload() {


    const elementsLoaded = useSelector(selectElementsLoaded)
    const { setRawDataAction } = useActions()


    const handleDropFile = (acceptedFiles: any) => {
        if (acceptedFiles.length) {
            acceptedFiles.forEach((file: any) => handleParseFile(file))
        }
    }

    const handleParseFile = (file: any) => {

        var reader = new FileReader();

        reader.onload = async function (e: any) {
            var data = e.target.result;
            let dataRead = read(data, { type: 'binary', cellStyles: true, cellDates: true });
            const sheet = dataRead.Sheets['20212809']
            let headers = utils.sheet_to_json(sheet, { header: 1 })[0] as string[]
            headers[1] = 'id'
            headers[2] = 'desc'
            headers[3] = 'start'
            headers[4] = 'end'
            headers[8] = 'actual' // Faktisk

            const sheetAsJson = utils.sheet_to_json(sheet, { range: 2, header: headers }) as RawLine[]
            setRawDataAction(sheetAsJson)

        };

        reader.readAsBinaryString(file)

    }


    const newDiv = useDropzone({ onDrop: (file: any) => handleDropFile(file), noClick: true });

    if (elementsLoaded) {
        return null
    }

    return (



        <Stack
            direction="row"
            p="16"
            alignItems="center"
            justifyContent={"center"}
            spacing="16"
        >

            <Stack
                border="2px dashed"
                borderColor={'gray.400'}
                width="400px"
                bg={newDiv.isDragActive ? 'blue.50' : ''}
                height="200px"
                borderRadius={"1.4rem"}
                {...newDiv.getRootProps({ className: 'dropzone' })}
                alignItems="center"
                justifyContent="center"
                p="2rem"
            >
                <input {...newDiv.getInputProps()} />
                <Text fontSize="3xl">{"📄"}</Text>
                <Text>{"Samlet plan (.xlsx)"}</Text>
            </Stack>

        </Stack>


    )

}




