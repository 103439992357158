import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "./useActions";
import useWindowDimensions from "./useWindowDimensions";
import { selectViewportWidth } from "../slice";


export default function useWindowDimensionsListener() {

    const dispatch = useDispatch()
    const viewWidth = useSelector(selectViewportWidth)
    const { setViewWidth } = useActions()
    const {
        width
    } = useWindowDimensions();

    useEffect(() => {

        if (viewWidth !== width) {
            setViewWidth(width)
        }

    }, [dispatch, width, viewWidth, setViewWidth])


    return null

}

