import { Stack, Text } from "@chakra-ui/react"
import { DateTime } from "luxon"
import { useSelector } from "react-redux"
import { selectMasterIndex, selectRawData } from "../../slice"


export default function DepsRow(props: { id: string }) {

    const { id } = props
    const rawData = useSelector(selectRawData)
    const masterIndex = useSelector(selectMasterIndex)
    const row = rawData[id]
    const endLocale = DateTime.fromISO(row.end as string).toLocaleString()
    const inbounds = masterIndex.inbounds[id]

    return (
        <tr key={row.seq}
            style={{
                backgroundColor: id[0] === 'p' ? '#ddd' : undefined,
                borderBottomColor: 'gray',
                borderBottom: '1px solid',
            }}
        >
            <td >{row.seq}</td>
            <td
                style={{
                    // backgroundColor: specLine.clss ? 'lightgreen' : undefined,
                    whiteSpace: 'nowrap'
                }}
            >{row.id}</td>

            <td width="10px" />
            <td>{endLocale}</td>
            <td width="10px" />
            <td >{row.desc}</td>
            <td width="10px" />
            <td >
                <Stack >
                    {inbounds.map(inId => {
                        const desc = rawData[inId]?.desc || ''
                        const id = rawData[inId]?.id || ''

                        return (
                            <Stack key={inId} direction="row">
                                {/* <Text>{inId}</Text> */}
                                <Text color="gray.500">{id}</Text>
                                <Text>{desc}</Text>
                            </Stack>
                        )
                    })}
                </Stack></td>

        </tr>
    )



}