import { Box, Drawer, DrawerOverlay, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "./features/grid/Grid";
import DepsInsert from "./features/menus/DepsInsert";
import DrawerMenu from "./features/menus/DrawerMenu";
import FooterBar from "./features/menus/FooterBar";
import SpecInsert from "./features/menus/SpecInsert";
import Unmatched from "./features/menus/Unmatched";
import ElementEdges from "./features/timeline/ElemementEdges";
import Elements from "./features/timeline/Elements";
import Legend from "./features/timeline/Legend";
import VerticalIndicators from "./features/timeline/VerticalIndicators";
import { selectMaxY, selectModal, selectViewportWidth, setSelectedId } from "./slice";

export const legendHeight = 60
export const leftBar = 24
export const footerBar = 32

export default function NuLayout() {

    const viewportWidth = useSelector(selectViewportWidth)
    const maxY = useSelector(selectMaxY)
    const modal = useSelector(selectModal)
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (

        <React.Fragment>
            <Box
                id="NuLayout"
                position="relative"
                overflowX={"hidden"}
                overflowY={"scroll"}
                maxW={"100%"}
                h={`${maxY + 1400}px`}
                onClick={() => dispatch(setSelectedId(''))}
            >

                <Box
                    id="LeftBarContainer"
                    bg="white"
                    minH={`${maxY}px`}
                    h={`${100}vh`}
                    w={`${leftBar}px`}
                    position={"fixed"}
                    top="0"
                    borderRight={"1px"}
                    borderColor="gray.300"
                    zIndex={3}
                >
                    <button
                        style={{
                            width: `${leftBar}px`
                        }}
                        onClick={onOpen}
                    >≡</button>
                </Box>

                <Box
                    id="LegendContainer"
                    h={`${legendHeight}px`}
                    w={`${viewportWidth}px`}
                    position={"fixed"}
                    top="0"
                    // left={`${leftBar}px`}
                    zIndex={2}
                    shadow="md"
                >

                    <Legend />

                </Box>

                <Box
                    id="FooterContainer"
                    // height={`${footerBar}px`}
                    w="100vw"
                    position={"fixed"}
                    bottom="0"
                    zIndex={4}
                    borderTop={"1px solid"}
                    borderTopColor="gray.300"
                >
                    <FooterBar />
                </Box>

                <Box
                    id="StaticBackgroundContainer"
                    // bg="khaki"
                    h="100vh"
                    w={`${viewportWidth}px`}
                    position={"fixed"}
                    // left={`${leftBar}px`}
                    top="0"
                    zIndex={-1}
                >
                    <VerticalIndicators />
                </Box>


                <ElementEdges />

                <Elements />

                <Box
                    id="ModalContainer"
                    zIndex={6}
                >
                    {modal === 'inspec' ? <SpecInsert /> : null}
                    {modal === 'unmatched' ? <Unmatched /> : null}
                    {modal === 'grid' ? <Grid /> : null}
                    {modal === 'indep' ? <DepsInsert /> : null}

                </Box>

                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                >
                    <DrawerOverlay />
                    <DrawerMenu />
                </Drawer>

            </Box>



        </React.Fragment>


    )

}