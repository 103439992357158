import { Button, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, Stack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { selectDependencies, selectElements, selectModal, selectShowGraph, setModal, setShowGraph } from "../../slice";

export default function DrawerMenu() {


    const dispatch = useDispatch()
    const showGraph = useSelector(selectShowGraph)
    const elements = useSelector(selectElements)
    const deps = useSelector(selectDependencies)
    const modal = useSelector(selectModal)


    const copyElementsDataAsMock = () => {
        const textToCopy = `export const mock: unknown = ${JSON.stringify(elements)}`
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Text copied to clipboard');
        }).catch(err => {
            alert('Failed to copy text: ');
        });
    };

    const copyDeps = () => {
        const textToCopy = `export const dependencies: Dependency[] = ${JSON.stringify(deps)}`
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Text copied to clipboard');
        }).catch(err => {
            alert('Failed to copy text: ');
        });
    };


    return (
        <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Spesielle funksjoner</DrawerHeader>

            <DrawerBody>
                <Stack
                >
                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={() => showGraph === true ? dispatch(setShowGraph(false)) : dispatch(setShowGraph(true))}
                    >Vis graf med alle relasjoner</Button>

                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={copyElementsDataAsMock}
                    >Kopier som eksempeldata (mock)</Button>

                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={copyDeps}
                    >Kopier relasjonsdata</Button>

                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={() => modal === 'inspec' ? dispatch(setModal('')) : dispatch(setModal('inspec'))}
                    >Input mapping (spec)</Button>

                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={() => modal === 'grid' ? dispatch(setModal('')) : dispatch(setModal('grid'))}
                    >Mapping</Button>

                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={() => modal === 'indep' ? dispatch(setModal('')) : dispatch(setModal('indep'))}
                    >Input relasjonsdata</Button>

                    <Button
                        justifyContent={"flex-start"}
                        variant="link"
                        onClick={() => modal === 'unmatched' ? dispatch(setModal('')) : dispatch(setModal('unmatched'))}
                    >Vis ikke-matchende mapping</Button>

                </Stack>
            </DrawerBody>

            <DrawerFooter>
                {/* <Button colorScheme='blue'>Save</Button> */}
            </DrawerFooter>
        </DrawerContent>
    )

}