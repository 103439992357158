import { useSelector } from "react-redux"
import { config } from "../../config"
import { selectElements, selectMasterIndex, selectSelectedId, selectShowDeps, selectVisible, selectXIndex, selectYIndex } from "../../slice"




export default function ElementEdges() {

    const masterIndex = useSelector(selectMasterIndex)
    const visible = useSelector(selectVisible)
    const elements = useSelector(selectElements)
    const xIndex = useSelector(selectXIndex)
    const yIndex = useSelector(selectYIndex)
    const istIds = masterIndex.clss['ist'] || []
    const showDeps = useSelector(selectShowDeps)
    const selectedId = useSelector(selectSelectedId)

    let paths: { id: string, d: string, display: 'highlight' | 'lowlight' | 'normal' }[] = []
    let maxY = 0

    if (!showDeps) {
        return null
    }

    istIds.forEach((istId: string) => {


        (masterIndex.inbounds[istId] || []).forEach(inId => {

            const elem = elements[inId]

            if (elem && 
                ((elem.lvl1) || !elem.lvl1)
                && Object.keys(yIndex).includes(inId)
                && Object.keys(yIndex).includes(istId)
                && Object.keys(xIndex).includes(inId)
                && Object.keys(xIndex).includes(istId)
            ) {

                const { top: y1 } = yIndex[inId]
                const { top: y2 } = yIndex[istId]
                const { end: x1 } = xIndex[inId]
                const { end: x2 } = xIndex[istId]

                maxY = Math.max(maxY, y1, y2)

                const display = selectedId === '' ? 'normal' : [istId, inId].includes(selectedId) ? 'highlight' : 'lowlight'

                paths.push({
                    id: `${istId}-${inId}`,
                    d: `M ${x1}, ${y1 + (config.diamondSize / 2)} L ${x2}, ${y2 + (config.diamondSize / 2)}`,
                    display: display
                })

            }
        })
    })

    return (
        <svg
            style={{
                position: 'absolute',
                height: `${maxY + config.diamondSize}px`,
                width: '100vw',
            }}
        >

            <defs>
            </defs>

            {paths.map(({id, d, display}) => (
                <path
                    key={`${id}`}
                    id='arrow-line'
                    // markerEnd='url(#head)'
                    strokeWidth={display === 'highlight' ? 1.6 : 1}
                    fill='none'
                    stroke='purple'
                    opacity={display === 'highlight' ? 1 : display === 'lowlight' ? 0.15 : 0.25}
                    d={d}
                />
            ))}



        </svg>
    )

}