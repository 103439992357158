import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { laneHeight } from "../../func/processYData";
import { selectElements } from "../../slice";


export const progressWidth = laneHeight * 4
const margin = laneHeight / 2

export default function ElementProgress(props: { id: string, x: number, y: number }) {

    const { id, x, y } = props
    const elements = useSelector(selectElements)
    const height = laneHeight - 2
    const { progress, actual, estimated } = elements[id]
    const left = x - progressWidth - margin
    const top = y + 1

    const borderColor = progress === 'ahead' || progress === 'done' ? 'green.500'
        : progress === 'lagging' || progress === 'late' ? 'red.500'
            : 'gray.500'

    const bgColor = progress === 'ahead' ? 'gray.50'
        : progress === 'done' ? 'green.400'
            : progress === 'lagging' ? 'gray.50'
                : progress === 'late' ? 'red.400'
                    : 'gray.50'

    const textColor = progress === 'late' || progress === 'done' ? 'white' : borderColor

    return (
        <React.Fragment>
            <Box
                position="absolute"
                left={`${left}px`}
                height={`${height}px`}
                width={`${progressWidth}px`}
                bg={`${bgColor}`}
                top={`${top}px`}
                border="1px solid"
                boxSizing="border-box"
                borderColor={`${borderColor}`}
            />
            <Text
                position="absolute"
                left={`${left}px`}
                top={`${top - 2}px`}
                fontSize={`${9}px`}
                color={`${textColor}`}
                width={`${progressWidth}px`}
                textAlign={"center"}
            >{Math.round((actual) * 100)}/{Math.round((estimated) * 100)}%</Text>
        </React.Fragment>
    )

}
