import { Box, Text, Tooltip } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../config';
import { selectElements, selectShowProgress, selectView, selectXIndex, selectYIndex, setSelectedId } from '../../slice';
import ElementProgress from './ElementProgress';



export default function Element(props: { id: string }) {

    const xIndex = useSelector(selectXIndex)
    const yIndex = useSelector(selectYIndex)
    const elements = useSelector(selectElements)
    const showProgress = useSelector(selectShowProgress)
    const view = useSelector(selectView)
    const dispatch = useDispatch()
    const { id } = props

    const onClick = (e: any) => {
        e.stopPropagation()
        dispatch(setSelectedId(id))
    }

    if (!(id in xIndex && id in yIndex)) {
        return null
    }

    const { clss, alt, desc, start: time, lvl1 = '', lvl2 = '' } = elements[id]

    const lvl2Elem = !lvl2 || view === 'standard' ? null : (Object.values(elements).find(el => el.lvl1 === lvl1 && el.lvl2 === lvl2 && el.clss === 'leveranse'))
    const lvl2Label = lvl2Elem ? elements[lvl2Elem.id].desc : ''

    const { start, end } = xIndex[id]
    const { top } = yIndex[id]
    const bg = clss === 'ist' ? 'purple.400'
        : clss === 'vit' ? 'tan'
            : clss === 'st-sit-plan' ? 'blue.200'
                : clss === 'sat-plan' ? 'green.200'
                    : clss === 'sat-done' ? 'green.400'
                        : clss === 'st-sit-done' ? 'blue.400'
                            : 'gray.400'
    const label = alt || desc

    const borderColor = clss === 'ist' ? 'purple.400'
        : clss === 'vit' ? 'tan'
            : clss === 'sat-done' ? 'green.400'
                : clss === 'st-sit-done' ? 'blue.400'
                    : 'gray.400'

    return (
        <React.Fragment>

            {showProgress ? (
                <ElementProgress id={id} x={start} y={top} />
            ) : null}

            <Box
                position="absolute"
                left={`${start}px`}
                height={`${config.diamondSize}px`}
                width={`${config.diamondSize}px`}
                bg={bg}
                top={`${top}px`}
                transform={`translateX(-50%) translateY(25%) rotate(45deg)`}
                border="2px solid"
                boxSizing="border-box"
                borderColor={borderColor}
                onClick={onClick}
            />
            <Text
                position="absolute"
                left={`${start + config.diamondSize}px`}
                top={`${top}px`}
                fontSize={`${10}px`}
                color="gray.700"
                whiteSpace={"nowrap"}
            >
                {view !== 'standard' ? (
                    <Tooltip
                        label={lvl2Label}
                        color="white"
                        fontSize={'xs'}>
                        <Text
                            as="span"
                        >{lvl1} {lvl2} {lvl1 || lvl2 ? '- ' : ''}</Text>
                    </Tooltip>
                ) : null}
                <Tooltip
                    label={`${DateTime.fromISO(time).toLocaleString()}`}
                    color="white"
                    fontSize={'xs'}>
                    <Text
                        as="span"
                    >{label}</Text>
                </Tooltip>
            </Text>


        </React.Fragment>
    )

}

