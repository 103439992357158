import React from "react";
import { useSelector } from "react-redux";
import { selectMasterIndex } from "../../slice";
import VerticalBreakIndicator from "./VerticalBreakIndicator";
import VerticalIndicator from "./VerticalIndicator";
import VerticalTodayIndicator from "./VerticalTodayIndicator";


export default function VerticalIndicators() {

    const masterIndex = useSelector(selectMasterIndex)

    const elements = [
        ...(masterIndex.clss['kib'] || []),
        ...(masterIndex.clss['eierskifte'] || []),
    ]

    return (
        <React.Fragment>
            <VerticalBreakIndicator id={'__påske'} />
            <VerticalBreakIndicator id={'__jul'} />
            <VerticalBreakIndicator id={'__sommerferie'} />
            {elements.map(id => (
                <VerticalIndicator
                    key={id}
                    id={id} />
            ))}
            <VerticalTodayIndicator />

        </React.Fragment>
    )

}