import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { config } from "../../config";
import { laneHeight } from "../../func/processYData";
import { selectXIndex, selectElements } from "../../slice";



export default function VerticalIndicator(props: { id: string }) {

    const xIndex = useSelector(selectXIndex)
    const elements = useSelector(selectElements)

    const { id } = props

    if (!(id in xIndex)) {
        return null
    }

    const x = xIndex[id].start
    const { clss, alt, lane } = elements[id]
    const top = (laneHeight * 6) + (lane || 0) * laneHeight
    const color = clss === 'kib' ? 'red'
        : clss === 'eierskifte' ? 'gray'
            : 'gray'

    return (
        <React.Fragment>
            <Box
                id="long-line"
                position="absolute"
                left={`${x}px`}
                width={`${config.milestoneLineWidth}px`}
                height={"100%"}
                bg={`${color}.700`}
                zIndex={-1}
                transform={`translateX(-50%)`}
                opacity={.25}

            />

            <Box
                id="diamond"
                position="absolute"
                left={`${x}px`}
                height={`${config.diamondSize}px`}
                width={`${config.diamondSize}px`}
                bg={`${color}.500`}
                top={`${top}px`}
                transform={`translateX(-50%) translateY(-50%) rotate(45deg)`}
            />

            <Text
                id="label"

                transformOrigin={"left bottom"}
                position="absolute"
                left={`${x}px`}
                top={`${top}px`}
                fontSize={`${9}px`}
                color={`${color}.600`}
                transform={`rotate(90deg)`}
            // bg="red"
            >{alt}</Text>
        </React.Fragment>
    )
}