

export interface RawLine {
    seq: string,
    id: string,
    desc: string,
    start: string | Date,
    end: string | Date,
    progress: ProgressState,
    actual: number,
    estimated: number,
}
export type RawLineDict = { [key: string]: RawLine }



// The output from the file reader ingestion
export interface BaseElement {
    id: string, //TODO depr?
    desc: string,
    start: string,
    end: string,
    progress: ProgressState,
    actual: number,
    estimated: number,
}

export type BaseElementDict = {[key: string]: BaseElement}
export type GroupIndex = { [key: string]: string[] }
export type MasterIndex = {
    rawChildren: GroupIndex,
    children: GroupIndex,
    inbounds: GroupIndex,
    clss: GroupIndex,
    filters: GroupIndex,
    eierskifte: GroupIndex,
}
export const defaultMasterIndex: MasterIndex = {
    children: {}, rawChildren: {}, inbounds: {}, clss: {}, 
    eierskifte: {
        1: [],
        2: [],
        3: [],
    }, 
    filters: { 
        satPlan: [],
        specIdsUnmatched: [],
        istPlanRelated: [],
    }
}
export type XIndex = { [key: string]: {start: number, end: number }}
export type YIndex = { [key: string]: {top: number, bottom?: number } }
export type Dependency = { from: string, to: string }


export const elementClass = [
    'ist', 'kib', 'eierskifte', 'leveranse', 'generic', 'vit',
    'u7c', 
    'test-do',
    'st-sit-plan',
    'st-sit-do',
    'st-sit-done',
    'sat-plan',
    'sat-do',
    'sat-done',
    'ld-ros',
] as const

export const views = [ 'standard', 'extended', 'satplan', 'satplan-chron', 'istplan'] as const

export const lvl1 = [
    '7501', 
    '7502',
    '7503',
    '7504',
    '7505',
    '7506',
    '7507',
    '7509',
    '7510',
    '7511',
    '7513',
    '7514',
    '7516',
    '7591',
] as const

export const lvl2 = [
    'L01',
    'L02',
    'L03',
    'L04',
    'L05',
    'L06',
    'L07',
    'L08',
    'L09',
    'L10',
    'L11',
    'L12',
    'L13',
    'L14',
    'L15',
    'L16',
    'L17',
    'L18',
    'L19',
    'L20',
] as const

export const eierskifter = [ 1, 2, 3 ] as const
export type Eierskifte = typeof eierskifter[number]


export const lookAheads = [ 0, 4, 6 ] as const

export type ElementClass = typeof elementClass[number]
export type Lvl1 = typeof lvl1[number]
export type Lvl2 = typeof lvl2[number]
export type LookAhead = typeof lookAheads[number]

export type SpecLine = {
    clss: ElementClass ,
    alt?: string,
    lane?: number,
    lvl1?: Lvl1,
    lvl2?: Lvl2,
    eie?: Eierskifte,
}

/**
 * lvl1 & lvl2 & leveranse: er selve leveransen
 * lvl1 & lvl2 & noe annet: er element i leveransen
 * lvl1 & leveranse: ugyldig
 * lvl1 & noe annet: er element på grunnivå av lvl1.
 */

export type SpecLineDict = {[key: string]: SpecLine}

export type CombinedElement = BaseElement & SpecLine
export type CombinedElementDict = {[key: string]: CombinedElement}
export type View = typeof views[number]
export type Modal = '' | 'grid' | 'inspec' | 'unmatched' | 'indep'
export type ProgressState = 'lagging' | 'late' | 'done' | 'ahead' | 'unknown'