import { Button, Stack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectMasterIndex, selectSpec } from "../../slice";
import { SpecLineDict, lvl1 } from "../../types.d";
import { footerBarHeight } from "../menus/FooterBar";



export default function GridCopySpecButton() {

    const specData = useSelector(selectSpec)
    const masterIndex = useSelector(selectMasterIndex)
    const specIdsUnmatched = masterIndex.filters.specIdsUnmatched
        .filter((id: any) => !lvl1.includes(id))

    const copySpec = () => {
        const textToCopy = `export const spec: SpecLineDict = ${JSON.stringify(specData, null, 2)}`
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Text copied to clipboard');
        }).catch(err => {
            alert('Failed to copy text: ');
        });
    };

    const copySpecUnmatched = () => {
        const filteredSpec: SpecLineDict = {}
        Object.entries(specData).forEach(([key, val]) => {
            if (!specIdsUnmatched.includes(key)) {
                filteredSpec[key] = val
            }
        })

        const textToCopy = `export const spec: SpecLineDict = ${JSON.stringify(filteredSpec, null, 2)}`
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Text copied to clipboard');
        }).catch(err => {
            alert('Failed to copy text: ');
        });
    };

    return (
        <Stack direction="row"
            position="fixed"
            right="0"
            bottom={`calc(50vh + ${footerBarHeight}px)`}
        >

            <Button

                size="xs"
                onClick={copySpecUnmatched}

            >Copy without unmatched</Button>

            <Button

                size="xs"
                onClick={copySpec}
            >Copy</Button>
        </Stack>
    )

}