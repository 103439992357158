// NetworkComponent.tsx

import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectDependencies, selectRawData, selectSpec } from "../../slice";


export type Node = { id: string; label: string; color: string; x?: number; y?: number, rad: number }

const NetworkComponent: React.FC = () => {
    const networkRef = useRef<HTMLDivElement>(null);

    const deps = useSelector(selectDependencies);
    const raw = useSelector(selectRawData);
    const spec = useSelector(selectSpec);

    useEffect(() => {
        if (networkRef.current && Object.keys(deps).length > 0 && Object.keys(raw).length > 0) {
            // Clear any previous SVG content
            d3.select(networkRef.current).selectAll("*").remove();

            const width = networkRef.current.clientWidth;
            const height = networkRef.current.clientHeight;

            // Create the SVG element and a group for zooming
            const svg = d3
                .select(networkRef.current)
                .append("svg")
                .attr("width", width)
                .attr("height", height);

            const g = svg.append("g");

            // Define arrowhead marker
            svg.append("defs")
                .append("marker")
                .attr("id", "arrowhead")
                .attr("viewBox", "-0.5 -0.5 1 1")
                .attr("refX", 6)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M -0.5,-0.5 L 0.5,0 L -0.5,0.5")
                .attr("fill", "#ccc");

            // Create a simulation for the nodes and edges
            const simulation = d3.forceSimulation()
                .force("link", d3.forceLink().id((d: any) => d.id).distance(100))
                .force("charge", d3.forceManyBody().strength(-300))
                .force("center", d3.forceCenter(width / 2, height / 2))
                .force("collide", d3.forceCollide().radius(30))

            // Prepare nodes and edges
            let nodes: Node[] = [];
            let edges: { source: string; target: string }[] = [];

            let involved = new Map();

            deps.forEach(dep => {
                if (raw[dep.from]?.desc && raw[dep.to]?.desc) {
                    edges.push({
                        source: dep.from,
                        target: dep.to,
                    });
                    involved.set(dep.from, true);
                    involved.set(dep.to, true);
                }
            });

            involved.forEach((_, key) => {
                const specLine = spec[key];
                let node: Node = {
                    id: key,
                    label: `${raw[key].id} ${raw[key].desc}`,
                    color: "gray", // Default color
                    rad: 6
                };

                if (specLine) {
                    if (specLine.clss === "ist") { node.color = "purple"; node.rad = 24 };
                    if (specLine.clss === "vit") { node.color = "khaki"; node.rad = 24 };
                    if (specLine.clss === "sat-done") { node.color = "green"; node.rad = 12 }
                }

                nodes.push(node);
            });

            // Add edges (lines) to the group
            const link = g
                .selectAll<SVGLineElement, any>(".link")
                .data(edges)
                .enter()
                .append("line")
                .attr("class", "link")
                .attr("stroke", "#ccc")
                .attr("stroke-opacity", 0.6)
                .attr("stroke-width", 2)
                .attr("marker-end", "url(#arrowhead)");

            // Add nodes (circles) to the group
            const node = g
                .selectAll<SVGCircleElement, any>(".node")
                .data(nodes)
                .enter()
                .append("circle")
                .attr("class", "node")
                .attr("r", d => d.rad)
                .attr("fill", d => d.color)
                .call(
                    d3.drag<SVGCircleElement, any>()
                        .on("start", (event, d) => {
                            if (!event.active) simulation.alphaTarget(0.3).restart();
                            d.fx = d.x;
                            d.fy = d.y;
                        })
                        .on("drag", (event, d) => {
                            d.fx = event.x;
                            d.fy = event.y;
                        })
                        .on("end", (event, d) => {
                            if (!event.active) simulation.alphaTarget(0);
                            d.fx = null;
                            d.fy = null;
                        })
                );

            // Add labels to nodes
            const labels = g
                .selectAll<SVGTextElement, any>(".label")
                .data(nodes)
                .enter()
                .append("text")
                .attr("class", "label")
                .attr("font-size", 10)
                .attr("text-anchor", "middle")
                .attr("dy", -10)
                .text(d => d.label);

            // Update positions of nodes and edges during simulation
            simulation.nodes(nodes).on("tick", () => {
                link
                    .attr("x1", d => (d.source as any).x)
                    .attr("y1", d => (d.source as any).y)
                    .attr("x2", d => (d.target as any).x)
                    .attr("y2", d => (d.target as any).y);

                node
                    .attr("cx", d => d.x!)
                    .attr("cy", d => d.y!);

                labels
                    .attr("x", d => d.x!)
                    .attr("y", d => d.y!);
            });

            simulation.force<d3.ForceLink<any, any>>("link")!.links(edges);

            // Add zooming and panning
            svg.call(
                d3.zoom<SVGSVGElement, any>()
                    .scaleExtent([0.1, 4])
                    .on("zoom", (event) => {
                        g.attr("transform", event.transform);
                    })
            );
        }
    }, [deps, raw, spec]);

    return <div ref={networkRef} style={{ width: "100vw", height: "100vh" }} />;
};

export default NetworkComponent;
