import { DateTime } from "luxon";


export default function getInterpolatedLinearFactorBetweenDates(start: DateTime, end: DateTime, today: DateTime) {
    
    // Ensure A, B, and today are Luxon DateTime objects
    const durationTotal = end.diff(start).toMillis();
    const durationToday = today.diff(start).toMillis();
  
    // Calculate the factor
    let factor = durationToday / durationTotal;
  
    // Clamp the factor to be within [0, 1]
    factor = Math.max(0, Math.min(1, factor));
  
    return factor;
  }