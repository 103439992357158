import { useSelector } from 'react-redux';
import InitMock from './InitMock';
import InitUpload from './InitUpload';
import Layout from './Layout';
import NetworkLayoutD3 from './features/graph/NetworkLayoutD3';
import useRecalculateX from './hooks/useRecalculateX';
import useRecalculateY from './hooks/useRecalculateY';
import useUpdateElements from './hooks/useUpdateElements';
import useWindowDimensionsListener from './hooks/useWindowDimensionsListener';
import { selectRawData, selectShowGraph } from './slice';


export default function Init() {

    useWindowDimensionsListener()
    useUpdateElements()
    useRecalculateX()
    useRecalculateY()

    const rawData = useSelector(selectRawData)
    const useMockData = process.env.REACT_APP_USE_MOCK_DATA === '1'
    const showGraph = useSelector(selectShowGraph)

    if (Object.keys(rawData).length > 0) {
        return showGraph ? <NetworkLayoutD3 /> : <Layout />
    }
    else if (useMockData) {
        return <InitMock />
    }
    else {
        return <InitUpload />
    }


}