import * as d3 from 'd3';
import { DateTime } from 'luxon';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { leftBar } from '../Layout';
import processXData from '../func/processXData';
import { selectElements, selectScaleX, selectTimelineEnd, selectTimelineStart, selectViewportWidth, setXIndex } from '../slice';


export default function useRecalculateX() {

    const dispatch = useDispatch()
    const viewWidth = useSelector(selectViewportWidth)
    const scaleX = useSelector(selectScaleX)
    const timelineStartRaw = useSelector(selectTimelineStart)
    const timelineEndRaw = useSelector(selectTimelineEnd)
    const elements = useSelector(selectElements)

    useEffect(() => {

        const timelineStart = DateTime.local(...timelineStartRaw)
        const timelineEnd = DateTime.local(...timelineEndRaw)
        const timeScale = d3
            .scaleTime()
            .domain([timelineStart.toJSDate(), timelineEnd.toJSDate()])
            .range([leftBar, viewWidth]);

        if (Object.keys(elements).length > 0 && viewWidth > 0) {
            const xIndex = processXData(elements, timeScale)
            dispatch(setXIndex(xIndex))
        }

    }, [dispatch, timelineStartRaw, timelineEndRaw, viewWidth, elements, scaleX])


    return null

}

