import processRawData from "../func/processRawData"
import { setRawData, setViewportWidth } from "../slice"
import store from "../store"


export const useActions = () => {

    const setRawDataAction = (input: any) => {
        const processed = processRawData(input)
        store.dispatch(setRawData(processed))
    }


    const setViewWidth = (input: number) => {
        store.dispatch(setViewportWidth(input))
    }


    return {
        setRawDataAction,
        setViewWidth,
    }

}