import { DateTime } from "luxon";
import { BaseElementDict, XIndex } from "../types.d";
import getLastCutoff from "./getLastCutoff";


export default function processXData(elements: BaseElementDict, timeScale: d3.ScaleTime<number, number, never>): XIndex {

    const ret: XIndex = {}

    Object.keys(elements).forEach(id => {
        const elem = elements[id]
        if (elem) {
            ret[id] = {
                start: timeScale(DateTime.fromISO(elem.start)),
                end: timeScale(DateTime.fromISO(elem.end)),
            }
        }
    })

    const now = DateTime.now()
    const lastCutoff = getLastCutoff(now)

    ret['__today'] = {
        start: timeScale(now),
        end: timeScale(now),
    }

    ret['__today6w'] = {
        start: timeScale(now),
        end: timeScale(now.plus({ weeks: 6 })),
    }

    ret['__today4w'] = {
        start: timeScale(now),
        end: timeScale(now.plus({ weeks: 4 })),
    }

    ret['__lastCutoff'] = {
        start: timeScale(lastCutoff),
        end: timeScale(lastCutoff),
    }

    ret['__påske'] = {
        start: timeScale(DateTime.local(2025, 4, 14)),
        end: timeScale(DateTime.local(2025, 4, 21)),
    }

    ret['__jul'] = {
        start: timeScale(DateTime.local(2024, 12, 20)),
        end: timeScale(DateTime.local(2025, 1, 5)),
    }

    ret['__sommerferie'] = {
        start: timeScale(DateTime.local(2025, 7, 1)),
        end: timeScale(DateTime.local(2025, 7, 31)),
    }

    return ret
}