import { Box, Center, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { leftBar, legendHeight } from "../../Layout";
import useLegendTimelineData from "../../hooks/useLegendTimelineData";
import { selectViewportWidth } from "../../slice";



export default function Legend() {


    const {
        yearSegments,
        monthSegments,
        weekSegments
    } = useLegendTimelineData()
    const viewportWidth = useSelector(selectViewportWidth)
    const width = (viewportWidth - leftBar)

    if (!viewportWidth) { return null }

    return (
        <Box
            id="Legend"
            width={`${width}px`}
            height={`${legendHeight}px`}
            bg="gray.50"
            borderBottom={"1px"}
            borderRight="1px"
            borderColor="gray.300"
        >

            {/* Year Row */}
            {yearSegments.map((segment, index) => (
                <Box
                    key={index}
                    position="absolute"
                    left={`${segment.start}px`}
                    width={`${segment.width}px`}
                    height={`${20}px`}
                    // padding="5px"
                    borderBottom={"1px"}
                    borderRight="1px"
                    borderColor="gray.300"
                    bg="gray.50"
                    top="0"

                >
                    <Text fontSize="sm" textAlign="center">{segment.label}</Text>
                </Box>
            ))}


            {/* Month Row */}
            {monthSegments.map((segment, index) => (
                <Box
                    key={index}
                    position="absolute"
                    left={`${segment.start}px`}
                    width={`${segment.width}px`}
                    height={`${20}px`}
                    borderRight="1px"
                    borderBottom={"1px"}
                    borderColor="gray.300"
                    bg="gray.50"
                    top={`${20}px`}
                >
                    <Text fontSize="xs" textAlign="center">{segment.label}</Text>
                </Box>
            ))}


            {/* Week Row */}
            {weekSegments.map((segment, index) => (
                <Center
                    key={index}
                    position="absolute"
                    left={`${segment.start}px`}
                    width={`${segment.width}px`}
                    height={`${20}px`}
                    borderRight="1px"
                    borderBottom={"1px"}
                    borderColor="gray.300"
                    bg="gray.50"
                    top={`${40}px`}
                >
                    <Text fontSize="9px" textAlign="center">{segment.label}</Text>
                </Center>
            ))}



        </Box>
    )

}