import { DateTime } from "luxon"
import { useSelector } from "react-redux"
import { selectRawData, selectSpec } from "../../slice"



export default function GridRow(props: { id: string, onSelect: (id: string) => void }) {

    const { id, onSelect } = props
    const rawData = useSelector(selectRawData)
    const specData = useSelector(selectSpec)

    const row = rawData[id]

    const specLine = specData[id] || {}
    const startLocale = DateTime.fromISO(row.start as string).toLocaleString()
    const endLocale = DateTime.fromISO(row.end as string).toLocaleString()
    const startLocaleToShow = startLocale === endLocale ? '' : startLocale

    return (
        <tr key={row.seq}
            onClick={() => onSelect(id)}
            style={{
                backgroundColor: id[0] === 'p' ? '#ddd' : undefined
            }}
        >
            <td >{row.seq}</td>
            <td
                style={{
                    backgroundColor: specLine.clss ? 'lightgreen' : undefined,
                    whiteSpace: 'nowrap'
                }}
            >{row.id}</td>
            <td width="10px" />
            <td>{startLocaleToShow}</td>
            <td width="10px" />
            <td>{endLocale}</td>
            <td width="10px" />
            <td >{row.desc}</td>
            {/* <td ><ElementParentSelect id={row.id} value={specLine.parent} callback={() => {}} /></td> */}


        </tr>
    )



}