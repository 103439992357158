import { CombinedElementDict, Lvl1, MasterIndex, View, YIndex } from "../types.d";

export const laneHeight = 12
export const sectionTopMargin = laneHeight
export const blockTopMargin = laneHeight / 2
export const blockBottomMargin = laneHeight / 2
export const sectionBottomMargin = laneHeight * 2

export default function processYData(elements: CombinedElementDict, masterIndex: MasterIndex, visible: Lvl1[], view: View): YIndex {

    const yIndex: YIndex = {}

    let acc = 180 // timeline margin
    let istMax = 0
    let vitMax = 0




    if (view === 'istplan') {

        const eie1 = Object.values(elements).find(e => e.eie === 1 && e.clss === 'eierskifte')
        const eie2 = Object.values(elements).find(e => e.eie === 2 && e.clss === 'eierskifte')
        const eie3 = Object.values(elements).find(e => e.eie === 3 && e.clss === 'eierskifte')

        if (!eie1 || !eie2 || !eie3) { }

        else {
            [eie1, eie2, eie3].forEach(eierskifte => {

                acc = acc + (blockTopMargin * 2) 
                const blockTop = acc
                yIndex[eierskifte.id] = { top: blockTop }

                structuredClone(masterIndex.eierskifte[eierskifte.eie!])
                .sort((a, b) => {
                    return elements[a].end < elements[b].end ? -1 : 1
                })
                .forEach((elemId, i) => {
                    yIndex[elemId] = { top: blockTop + ((i +1) * laneHeight) }
                })


                acc = acc + (masterIndex.eierskifte[eierskifte.eie!].length + 1) * laneHeight
                const blockBottom = acc + (blockBottomMargin)
                yIndex[eierskifte.id]['bottom'] = blockBottom
                acc = blockBottom

            })

            acc = acc + (2 * laneHeight)

            structuredClone(masterIndex.filters.istPlanRelated)
            .sort((a, b) => {
                return elements[a].end < elements[b].end ? -1 : 1
            })
            .forEach(elemId => {
                yIndex[elemId] = { top: acc }
                acc = acc + laneHeight
            })
        }
    }

    else {
        masterIndex.clss['ist'].forEach(id => {
            const elem = elements[id]
            const { lane = 0 } = elem
            if (elem) {
                const y = acc + laneHeight * lane
                yIndex[id] = { top: y }
                istMax = y > istMax ? y : istMax
            }
        })
    
        masterIndex.clss['vit'].forEach(id => {
            const elem = elements[id]
            const { lane = 0 } = elem
            if (elem) {
                const y = acc + laneHeight * lane
                yIndex[id] = { top: y }
                vitMax = vitMax > y ? vitMax : y
            }
        })
    
        acc = Math.max(istMax, vitMax) + laneHeight * 3
    }

    if (view === 'satplan') {
        structuredClone(masterIndex.filters.satPlan)
            .sort((a, b) => {
                const lvl1Sort = Number(elements[a].lvl1) - Number(elements[b].lvl1)
                const lvl2Sort = Number(elements[a].lvl2) - Number(elements[b].lvl2)
                const lvl3Sort = (elements[a].lane || 0) - (elements[b].lane || 0)
                return lvl1Sort !== 0 ? lvl1Sort : lvl2Sort !== 0 ? lvl2Sort : lvl3Sort
            })
            .forEach(elemId => {
                yIndex[elemId] = { top: acc }
                acc = acc + laneHeight
            })
        // return yEie(elements, satPlanElems)
    }

    else if (view === 'satplan-chron') {
        acc = acc - laneHeight * 10
        structuredClone(masterIndex.filters.satPlan)
            .sort((a, b) => {
                return elements[a].end < elements[b].end ? -1 : 1
            })
            .forEach(elemId => {
                yIndex[elemId] = { top: acc }
                acc = acc + laneHeight
            })
    }



    else if (view === 'standard' || view === 'extended') {
        const lvl1Sorted = structuredClone(masterIndex.clss['u7c']).sort((a, b) => (elements[a].lane || 0) - (elements[b].lane || 0))
        lvl1Sorted.forEach(lvl1 => {
            const lvl1El = elements[lvl1]
            if (lvl1El && visible.includes(lvl1 as Lvl1)) {

                const sectionTop = acc

                yIndex[lvl1] = { top: sectionTop }
                acc = acc + sectionTopMargin


                // Top field for elements not in lvl2

                const elementsNotInLvl2 = masterIndex.children[lvl1]
                    .filter(id => elements[id].clss !== 'leveranse' && !elements[id].lvl2)

                if (elementsNotInLvl2.length > 0) {
                    acc = acc + blockTopMargin
                    let blockLaneMax = 0
                    elementsNotInLvl2.forEach(elemId => {
                        const { lane = 0 } = elements[elemId]
                        blockLaneMax = Math.max(lane, blockLaneMax)
                        const elemTop = acc + (lane * laneHeight)
                        yIndex[elemId] = { top: elemTop }
                    })
                    acc = acc + (blockLaneMax + 1) * laneHeight
                }

                // Lvl2s

                const lvl2IdsSorted = masterIndex.children[lvl1]
                    .filter(id => elements[id].clss === 'leveranse')
                    .sort((a, b) => (elements[a].lane || 0) - (elements[b].lane || 0))

                lvl2IdsSorted.forEach(lvl2Id => {
                    const { lvl2 } = elements[lvl2Id]
                    acc = acc + blockTopMargin
                    const blockTop = acc
                    yIndex[lvl2Id] = { top: blockTop }
                    let blockLaneMax = 0

                    const combinedId = `${lvl1}-${lvl2}`


                    if (view === 'extended') {
                        masterIndex.rawChildren[combinedId].forEach((elemId, i) => {
                            const j = i + 1
                            blockLaneMax = Math.max(j, blockLaneMax)
                            const elemTop = acc + (j * laneHeight)
                            yIndex[elemId] = { top: elemTop }
                        })
                    }

                    else {
                        masterIndex.children[combinedId].forEach((elemId) => {
                            const { lane = 0 } = elements[elemId]
                            blockLaneMax = Math.max(lane, blockLaneMax)
                            const elemTop = acc + (lane * laneHeight)
                            yIndex[elemId] = { top: elemTop }
                        })
                    }

                    acc = acc + (blockLaneMax + 1) * laneHeight
                    const blockBottom = acc + blockBottomMargin
                    yIndex[lvl2Id]['bottom'] = blockBottom
                    acc = blockBottom
                })

                const sectionBottom = Math.max(acc, sectionTop + 100)
                yIndex[lvl1]['bottom'] = sectionBottom
                acc = sectionBottom + sectionBottomMargin

            }
        })
    }




    return yIndex
}


