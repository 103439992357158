import { Box, Stack } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { selectMasterIndex, selectRawData } from "../../slice"
import { lvl1 } from "../../types.d"
import { footerBarHeight } from "./FooterBar"



export const unmatchedWidth = 1200


export default function Unmatched() {


    const masterIndex = useSelector(selectMasterIndex)
    const specIdsUnmatched = masterIndex.filters['specIdsUnmatched']
        .filter((id: any) => !lvl1.includes(id))
    const rawData = useSelector(selectRawData)

    return (
        <Box
            bg="whiteAlpha.800"
            position={"fixed"}
            right="0"
            bottom={`${footerBarHeight}px`}
            width={`${unmatchedWidth}px`}
            // height={"45vh"}
            shadow="xl"
            // overflow={"scroll"}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="3"
            p="2"
            m="2"
        >


            <Stack>
                <Stack
                    border="1px solid"
                    borderColor="gray.400"
                    height={"40vh"}
                    overflow="scroll"
                >

                    <table
                        style={{
                            fontSize: "11px",
                        }}
                    >
                        <tbody>
                            {specIdsUnmatched.map((id: string) => {
                                const raw = (rawData[id] || {})
                                const typeId = raw.id
                                const desc = raw.desc

                                return (
                                    <tr key={id}>
                                        <td>{id}</td>
                                        <td>{typeId}</td>
                                        <td>{desc}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </Stack>

            </Stack>



        </Box>
    )


}