import { Box, Stack } from "@chakra-ui/react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { selectRawOrder } from "../../slice"
import { footerBarHeight } from "../menus/FooterBar"
import GridCopySpecButton from "./GridCopySpecButton"
import GridRow from "./GridRow"
import GridRowSelected from "./GridRowSelected"


export const gridWidth = 1200


export default function Grid() {

    const [selectedId, setSelectedId] = useState('')
    const rawOrder = useSelector(selectRawOrder)


    return (
        <Box
            bg="whiteAlpha.800"
            position={"fixed"}
            right="0"
            bottom={`${footerBarHeight}px`}
            width={`${gridWidth}px`}
            shadow="xl"
            border="1px solid"
            borderColor="gray.400"
            borderRadius="3"
            p="2"
            m="2"
        >

            <GridCopySpecButton />

            <Stack>
                <Stack
                    height={"5vh"}
                    direction="row"
                >

                    {!!selectedId ?
                        <GridRowSelected key={selectedId} id={selectedId} />
                        : null}

                </Stack>
                <Stack
                    border="1px solid"
                    borderColor="gray.400"
                    overflow="scroll"
                    height={"40vh"}
                >
                    <table
                        style={{
                            fontSize: "11px",
                        }}
                    >
                        <tbody>
                            {rawOrder.map((id: string) => (
                                <GridRow key={id} id={id} onSelect={setSelectedId} />
                            ))}
                        </tbody>
                    </table>
                </Stack>

            </Stack>


        </Box>
    )


}